import React, { useEffect, useState } from "react";
import MappingModal from "../../components/MappingModal";
import CategoryModal from "../../components/CategoryModal";
import DivisionModal from "../../components/DivisionModal";
import { showToast } from "../../components/Toaster";
// temporary added by alvin
import mappingData from "../../data/mappingData.json";


import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  Chip,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";

function isNumeric(value: any) {
  return /^-{0,1}\d+$/.test(value);
}

interface DivisionObject {
    id: number;
    name: String;
    categories: CategoryObject[];
};

interface ItemObject{
    id: number;
    name: string;
    mapping: string;
  }
  
  interface CategoryObject{
    id: number;
    name: string;
    items: ItemObject[];
  }

const Mappings = () => {
    const [selectedDivisionId, setSelectedDivisionId] = useState<any>('all');
    const [selectedCategoryId, setSelectedCategoryId] = useState<any>('all');
    const [mappingModalIsOpen, setMappingModalIsOpen] = useState(false);
    const [categoryModalIsOpen, setCategoryModalIsOpen] = useState(false);
    const [divisionModalIsOpen, setDivisionModalIsOpen] = useState(false);
    const [editCategory, setEditCategory] = useState<any>(null);
    const [editDivision, setEditDivision] = useState<any>(null);
    const [editItemMapping, setEditItemMapping] = useState<any>(null);

    const [divisions, setDivisions] = useState<DivisionObject[]>([]);
       
    useEffect(() => {
        // const apiUrl = process.env.REACT_APP_API_URL;
        // fetch(`${apiUrl}/api2/mappings`)
        // .then((response) => response.json())
        // .then((data) => {
        //     setDivisions(data.divisions);
        // })
        // .catch((error) => console.error("Error fetching the data:", error));

        setDivisions(mappingData.divisions);
    
    }, []);

    // Division Change handler
    const handleDivisionChange = (event: { target: { value: any } }) => {
        const selectedId = event.target.value;
        setSelectedDivisionId(selectedId);
        setSelectedCategoryId(null); // Reset selected category when division changes
    };

    // Division Add handler
    // const handleAddDivisionClick = () => {
    //     setDivisionModalIsOpen(true);
    //     setEditDivision(null); // Ensure we are adding a new division
    // };

    // Division ADD Save handler
    // const handleAddDivisionSaveClick = (name: string) => {
    //     const newDivisionId = divisions.reduce((maxId, division) => Math.max(maxId, division.id), 0) + 1;
    //     const newDivision = { id: newDivisionId, name, categories: [] };

    //     const updatedDivisions = [...divisions, newDivision];

    //     //updateData(updatedDivisions);
    //     setDivisionModalIsOpen(false);
    //     showToast({
    //         message: "Division added successfully.",
    //         severity: "success",
    //       });

    // };

    // Division UPDATE/EDIT Save handler
    // const handleEditDivisionSaveClick = (name: string) => {
    //     const updatedDivisions = divisions.map(division =>
    //         division.id === editDivision.id ? { ...division, name } : division
    //     );

    //     //updateData(updatedDivisions);
    //     setDivisionModalIsOpen(false);
    //     showToast({
    //         message: "Division updated successfully!",
    //         severity: "success",
    //       });

    // };  

    // Category EDIT handler
    const handleEditCategoryClick = (divisionId: number, category: any) => {
        setEditCategory(category);
        setSelectedDivisionId(divisionId.toString());
        setCategoryModalIsOpen(true);
    };

    // Category ADD Save handler
    const handleAddCategorySaveClick = (name: string) => {
        const selectedDivision = divisions.find(division => division.id === parseInt(selectedDivisionId, 10));
        if (!selectedDivision) {
            showToast({
                message: "Selected division not found.",
                severity: "error",
            });
            return;
        }

        const newCategoryId = selectedDivision.categories.reduce((maxId, category) => Math.max(maxId, category.id), 0) + 1;
        const newCategory = { id: newCategoryId, name, items: [] };

        const updatedDivisions = divisions.map(division => {
            if (division.id === parseInt(selectedDivisionId, 10)) {
                return {
                    ...division,
                    categories: [...division.categories, newCategory]
                };
            }
            return division;
        });

        // updateData(updatedDivisions);
        setCategoryModalIsOpen(false);
        showToast({
            message: "Category added successfully!",
            severity: "success",
        });

    };

    // Category EDIT Save handler
    const handleEditCategorySaveClick = (name: string) => {
        const updatedDivisions = divisions.map(division => {
            if (division.id === parseInt(selectedDivisionId, 10)) {
                return {
                    ...division,
                    categories: division.categories.map(category =>
                        category.id === editCategory.id ? { ...category, name } : category
                    )
                };
            }
            return division;
        });

        // updateData(updatedDivisions);
        setCategoryModalIsOpen(false);
        showToast({
            message: "Category updated successfully!",
            severity: "success",
        });
    };

    // Category Change hander
    const handleCategoryChange = (event: { target: { value: any; }; }) => {
        const selectedId = event.target.value;
        setSelectedCategoryId(selectedId === '' ? null : parseInt(selectedId, 10));
    };

    // Category ADD handler
    const handleAddCategoryClick = () => {
        if (!isNumeric(selectedDivisionId) || selectedDivisionId === 'all') {
            showToast({
                message: "Please select a specific division",
                severity: "error",
            });
            return;
        }
        setCategoryModalIsOpen(true);
        setEditCategory(null); // Ensure we are adding a new category
    };

    // Item & Mapping ADD handler
    const handleAddMappingClick = () => {
        if (!isNumeric(selectedDivisionId) || selectedDivisionId === 'all' || !selectedCategoryId) {
            showToast({
                message: "Please select a specific division and category.",
                severity: "error",
            });
            return;
        }
        setMappingModalIsOpen(true);
        setEditItemMapping(null);
    };

    // Item & Mapping EDIT handler
    const handleEditItemMappingClick = (divisionId: number, categoryId: number, item: any) => {
        setEditItemMapping(item);
        setEditDivision(divisionId);
        setEditCategory(categoryId);
        setMappingModalIsOpen(true);
    };

    // Item & Mapping ADD Save handler    
    const handleAddItemMappingSaveClick = (name: string, mapping: string) => {
        if (!isNumeric(selectedDivisionId) || selectedDivisionId === 'all' || !selectedCategoryId) {
            showToast({
                message: "Please select a specific division and category.",
                severity: "error",
            });
            return;
        }

        const newId = divisions.reduce((maxId, division) => {
            if (division.id === parseInt(selectedDivisionId, 10)) {
                division.categories.forEach(category => {
                    if (category.id === selectedCategoryId) {
                        const maxItemId = category.items.reduce((max, item) => (item.id > max ? item.id : max), 0);
                        maxId = Math.max(maxId, maxItemId);
                    }
                });
            }
            return maxId;
        }, 0) + 1;

        const newItem = { id: newId, name, mapping };

        const updatedDivisions = divisions.map(division => {
            if (division.id === parseInt(selectedDivisionId, 10)) {
                return {
                    ...division,
                    categories: division.categories.map(category => {
                        if (category.id === selectedCategoryId) {
                            return {
                                ...category,
                                items: [...category.items, newItem]
                            };
                        }
                        return category;
                    })
                };
            }
            return division;
        });

        // updateData(updatedDivisions);
        setMappingModalIsOpen(false);
        showToast({
            message: "Item and Mapping added successfully!",
            severity: "success",
        });
    };

    // Item & Mapping EDIT Save handler
    const handleEditItemMappingSaveClick = (name: string, mapping: string) => {
        const updatedDivisions = divisions.map(division => {
            if (division.id === editDivision.id) {
                return {
                    ...division,
                    categories: division.categories.map(category => {
                        if (category.id === editCategory.id) {
                            return {
                                ...category,
                                items: category.items.map(item =>
                                    item.id === editItemMapping.id ? { ...item, name, mapping } : item
                                )
                            };
                        }
                        return category;
                    })
                };
            }
            return division;
        });

        // updateData(updatedDivisions);
        setMappingModalIsOpen(false);
        showToast({
            message: "Item and mapping updated successfully!",
            severity: "success",
        });
    };

    // Item & Mapping Delete handler
    const handleDeleteItemMappingClick = (divisionId: number, categoryId: number, itemId: number) => {
        const updatedDivisions = divisions.map(division => {
            if (division.id === divisionId) {
                return {
                    ...division,
                    categories: division.categories.map(category => {
                        if (category.id === categoryId) {
                            return {
                                ...category,
                                items: category.items.filter(item => item.id !== itemId)
                            };
                        }
                        return category;
                    })
                };
            }
            return division;
        });
        //updateData(updatedDivisions);
        showToast({
            message: "Item and mapping deleted successfully",
            severity: "success",
        });

    };

    // Update JSON file
    const updateData = (updatedDivisions: any) => {
        fetch('http://localhost:5000/api/divisions', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ divisions: updatedDivisions })
        })
        .then(response => response.json())
        .then(data => {
            setDivisions(updatedDivisions);
            showToast({
                message: "Data updated successfully",
                severity: "success",
            });
        })
        .catch(error => {
            console.error('Error updating the data:', error);
            showToast({
                message: "Failed to update data",
                severity: "error",
            });
        });
    };    
    // Mapping list filter
    const filteredCategories = selectedDivisionId === 'all'
        ? divisions.flatMap(division => division.categories)
        : divisions.find(division => division.id === parseInt(selectedDivisionId, 10))?.categories || [];

    return (
        <>
        <Grid container justifyContent="space-between" spacing={24}>
            <Grid item>
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
                Mappings
            </Typography>
            </Grid>
        </Grid>
        <Box p={2}>
            {/* <ToastContainer /> */}
            <Box mb={2}>
                <FormControl variant="standard"
                    sx={{ width: '50%' }}
                >
                    <InputLabel id="division-label">Division</InputLabel>
                    <Select
                        labelId="division-label"
                        id="division"
                        value={selectedDivisionId}
                        onChange={handleDivisionChange}
                        label="Division"
                    >
                        <MenuItem value="all"><em>None</em></MenuItem>
                        {divisions.map(division => (
                            <MenuItem key={division.id} value={division.id}>
                                {division.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {/* Temporary removed not required */}
                {/* <Chip
                    label={<span>New Division</span>}
                    color="primary"
                    variant="outlined"
                    onClick={handleAddDivisionClick}
                    sx={{ mt: 2, marginLeft: '10px', width: '120px', borderRadius: 50 }}
                    clickable
                /> */}
            </Box>

            {selectedDivisionId !== 'all' && (
                <Box mb={2}>
                    <FormControl variant="standard"
                        sx={{ width: '50%' }}
                    >
                        <InputLabel id="category-label">Category</InputLabel>
                        <Select
                            labelId="category-label"
                            id="category"
                            value={selectedCategoryId || ''}
                            onChange={handleCategoryChange}
                            label="Category"
                        >
                            <MenuItem value=""><em>All Categories</em></MenuItem>
                            {filteredCategories.map(category => (
                                <MenuItem key={category.id} value={category.id}>
                                    {category.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Chip
                        label={<span>New Category</span>}
                        color="primary"
                        variant="outlined"
                        onClick={handleAddCategoryClick}
                        sx={{ mt: 2, marginLeft: '10px', width: '120px', borderRadius: 50 }}
                        clickable
                    />
                </Box>
            )}
            <div style={{ textAlign: 'center' }}>
                <Chip
                    label={"categories and items".split('').map((char, index) => (
                        <span key={index}>{char.toUpperCase()}&nbsp;</span>
                    ))}
                    color="primary"
                    sx={{ fontWeight: 'normal', m: 2, width: '40%', borderRadius: 50 }}
                />
            </div>    
            {/* Item and Mapping List */}
            {selectedDivisionId !== 'all' &&
                filteredCategories
                    .filter(category => !selectedCategoryId || category.id === selectedCategoryId)
                    .map(category => (
                        <Box key={category.id} mb={2}>
                            <Typography variant="h5" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Chip
                                    label={`${category.name}`.split('').map((char, index) => (
                                        <span key={index}>{char.toUpperCase()}&nbsp;</span>
                                    ))}
                                    // label={<span>{`${category.name}`}</span>}
                                    color="secondary"
                                    variant="outlined"
                                    sx={{ fontWeight: 'normal', width: '250px', borderRadius: 50 }}
                                />
                                {isNumeric(selectedCategoryId) && (
                                    <Box>
                                        <Chip
                                            label={<span>New Item</span>}
                                            color="primary"
                                            onClick={handleAddMappingClick}
                                            sx={{ width: '80px', borderRadius: 50 }}
                                            clickable
                                        />
                                        <Chip
                                            label={<span>Edit</span>}
                                            color="success"
                                            variant="outlined"
                                            onClick={() => handleEditCategoryClick(parseInt(selectedDivisionId, 10), category)}
                                            sx={{ marginLeft: '5px', width: '80px', borderRadius: 50 }}
                                            clickable
                                        />
                                        <Chip
                                            label={<span>Delete</span>}
                                            color="secondary"
                                            variant="outlined"
                                            // onClick={() => handleDeleteCategoryClick(parseInt(selectedDivisionId, 10), category.id)}
                                            sx={{ marginLeft: '5px', width: '80px', borderRadius: 50 }}
                                            clickable
                                        />

                                    </Box>
                                )}
                            </Typography>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">ID</TableCell>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Mapping</TableCell>
                                            {isNumeric(selectedCategoryId) && (<TableCell align="center">Actions</TableCell>)}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {category.items.map(item => (
                                            <TableRow key={item.id}>
                                                <TableCell width="5%" align="center">{item.id}</TableCell>
                                                <TableCell width="25%">{item.name}</TableCell>
                                                <TableCell width="25%">{item.mapping}</TableCell>
                                                {isNumeric(selectedCategoryId) && (<TableCell width="12%" align="center">

                                                    <Chip
                                                        label={<span style={{ textAlign: 'center' }}>Edit</span>}
                                                        color="primary"
                                                        onClick={() => handleEditItemMappingClick(parseInt(selectedDivisionId, 10), category.id, item)}
                                                        sx={{ marginLeft: '5px', marginTop: '1px', marginBottom: '1px', width: '70px', height: '27px', borderRadius: 50 }}
                                                        clickable
                                                    />
                                                    <Chip
                                                        label={<span style={{ textAlign: 'center' }}>Delete</span>}
                                                        color="secondary"
                                                        variant='outlined'
                                                        onClick={() => handleDeleteItemMappingClick(parseInt(selectedDivisionId, 10), category.id, item.id)}
                                                        sx={{ marginLeft: '5px', marginTop: '1px', marginBottom: '1px', width: '70px', height: '27px', borderRadius: 50 }}
                                                        clickable
                                                    />

                                                </TableCell>
                                                )}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    ))}

            {/* Modals */}
            <MappingModal
                isOpen={mappingModalIsOpen}
                onRequestClose={() => setMappingModalIsOpen(false)}
                onSave={editItemMapping ? handleEditItemMappingSaveClick : handleAddItemMappingSaveClick}
                initialName={editItemMapping ? editItemMapping.name : ''}
                initialMapping={editItemMapping ? editItemMapping.mapping : ''}
                title={editItemMapping ? 'EDIT ITEM & MAPPING' : 'ADD ITEM & MAPPING'}
            />

            <CategoryModal
                isOpen={categoryModalIsOpen}
                onRequestClose={() => setCategoryModalIsOpen(false)}
                onSave={editCategory ? handleEditCategorySaveClick : handleAddCategorySaveClick}
                initialName={editCategory ? editCategory.name : ''}
                title={editCategory ? 'EDIT CATEGORY' : 'ADD CATEGORY'}
            />
            {/*<DivisionModal
                isOpen={divisionModalIsOpen}
                onRequestClose={() => setDivisionModalIsOpen(false)}
                onSave={editDivision ? handleEditDivisionSaveClick : handleAddDivisionSaveClick}
                initialName={editDivision ? editDivision.name : ''}
                title={editDivision ? 'Edit Division' : 'Add Division'}
            />*/}        
        </Box>
    </>
    )
};

export default Mappings;
