import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { ServiceCall } from '../../models/ServiceCall';
import { Button, Grid } from '@mui/material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

let assignedCallsModalOpen = (name: string, calls: ServiceCall[]) => {};
let assignedCallsModalClose = () => {};

type AssignedCallsDialogProps = {
    onClose?: () => void;
    svcCallOpener: (call: ServiceCall) => void;
}

export default function CustomizedDialogs({ onClose, svcCallOpener }: AssignedCallsDialogProps) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [name , setName] = React.useState('');
  const [calls , setCalls] = React.useState<ServiceCall[]>([]);

  assignedCallsModalOpen = (name: string, calls: ServiceCall[]) => {
    setOpen(true);
    setName(name);
    setCalls(calls);
  };

  assignedCallsModalClose = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  };

  

  return (
    <div>
      <BootstrapDialog
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            return false;
          }
        }}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "800px", // Set your width here
            },
          },
        }}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={assignedCallsModalClose}
        >
          Calls for {name}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid container>
            {calls.map((call, i) => (
              <Grid key={i} >
                <Button sx={{m: 2}} variant='contained' onClick={(e) => {
                  svcCallOpener(call);
                  setOpen(false)
                }}>
                  {call.external_id ?? call.id}
                </Button>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions></DialogActions>
      </BootstrapDialog>
    </div>
  );
}

export { assignedCallsModalClose, assignedCallsModalOpen }
