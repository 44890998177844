import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { emptyUser, User } from '../../models/User';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Switch, TextField, Typography } from '@mui/material';
import { useAxios } from '../../hooks/useAxios';
import { AxiosError } from 'axios';
import { ApiResponse } from '../../models/ApiResponse';
import { showToast } from '../../components/Toaster';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

let userModalOpen = (user:User) => {};
let userModalClose = () => {};

type UserDialogProps = {
    onClose?: () => void;
}

export default function CustomizedDialogs({ onClose }: UserDialogProps) {
  const [open, setOpen] = React.useState(false);
  const [ _user, setUser ] = React.useState<User>(emptyUser());
  const [loading, setLoading] = React.useState(false);
  const [isAdd, setIsAdd] = React.useState(false)

  const axios = useAxios();

  userModalOpen = (user: User) => {
    setUser(user);

    setIsAdd(user.id === undefined)

    setOpen(true);
  };
  userModalClose = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  };

  return (
    <div>
      <BootstrapDialog
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            return false;
          }
        }}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={userModalClose}
        >
          {isAdd ? "Add" : "Edit"} User
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Box
            component="form"
            sx={{
              "& > :not(style)": { mt: 1 },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              fullWidth
              className="my-2"
              id="user-email"
              type="email"
              autoComplete="off"
              disabled={!isAdd}
              onChange={(e) => {
                setUser({ ..._user, email: e.target.value || "" });
              }}
              value={_user?.email}
              label="email"
            />
            <TextField
              fullWidth
              className="my-2"
              id="user-name"
              autoComplete="off"
              onChange={(e) => {
                setUser({ ..._user, name: e.target.value || "" });
              }}
              value={_user?.name}
              label="name"
            />
            <TextField
              label="Password"
              type="password"
              id="user-pass"
              autoComplete="off"
              fullWidth
              onChange={(e) => {
                setUser({ ..._user, password: e.target.value || "" });
              }}
              value={_user?.password}
            ></TextField>

            <Grid container>
              <Grid item>
                <FormControl>
                  <InputLabel id="demo-simple-select-label">Access</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={_user.access_level}
                    label="Access"
                    onChange={(e) => {
                      setUser({
                        ..._user,
                        access_level: e.target.value as number,
                      });
                    }}
                  >
                    <MenuItem value={0}>Technician</MenuItem>
                    <MenuItem value={1}>Dispatch</MenuItem>
                    <MenuItem value={2}>Admin</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl sx={{ m: 2}}>
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={(e) => {
                          setUser({ ..._user, active: e.target.checked });
                        }}
                        checked={_user?.active}
                      />
                    }
                    label="Enabled"
                  />
                </FormControl>
              </Grid>
            </Grid>

            <hr />
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Optional Fields</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TextField
                  label="Mobile"
                  id="user-mobile"
                  fullWidth
                  onChange={(e) => {
                    setUser({ ..._user, mobile: e.target.value || "" });
                  }}
                  value={_user?.mobile}
                ></TextField>
                <TextField
                  label="City"
                  id="user-city"
                  fullWidth
                  onChange={(e) => {
                    setUser({ ..._user, city: e.target.value || "" });
                  }}
                  value={_user?.city}
                ></TextField>
              </AccordionDetails>
            </Accordion>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={loading}
            onClick={() => {
              setLoading(true);
              axios
                .post<ApiResponse<User>>(
                  "users/addorupdate",
                  JSON.stringify(_user)
                )
                .then((response) => {
                  const apiResponse = response.data;

                  if (apiResponse.success) {
                    showToast({ message: "User saved!", severity: "success" });
                  } else {
                    showToast({
                      message: "An error occurred!",
                      severity: "error",
                    });
                  }
                })
                .catch((reason: AxiosError) => {
                  showToast({
                    message: `An Error occurred: ${reason.code}`,
                    severity: "error",
                  });
                })
                .finally(() => {
                  setLoading(false);
                  userModalClose();
                });
            }}
          >
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

export { userModalClose, userModalOpen }
