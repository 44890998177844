import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, TextField, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAxios } from '../../hooks/useAxios';
import { ApiResponse } from '../../models/ApiResponse';
import { Machine, MeterRead, METER_TYPE_ENUM } from '../../models/Machine';
import { useDataProvider } from '../../hooks/useDataProvider';
import MeterUpdater from './MeterUpdater';

type Props = {
    callid: string;
    counts?: MeterRead[]
}

function MeterCountComponent({ callid, counts}: Props) {

    const [loading, setLoading] = useState(false);
    const axios = useAxios();
    const { fetchMachines } = useDataProvider();

    const updateCount = useCallback((type:number, count:number) => {

        setLoading(true);
        axios.post<ApiResponse<Machine>>(`machines/updatemeter`, JSON.stringify({ id: callid, type, count }))
        .then(res => {
            const apiResp = res.data;
            alert(apiResp.message);
            fetchMachines();
        })
        .finally(() => {
            setLoading(false)
        })
    }, [axios, callid, setLoading, fetchMachines])
  
    return (
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel111a-content"
          id="panel111a-header"
        >
          <Typography>Meter Count</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} sx={{ m: 2 }}>
            {METER_TYPE_ENUM.map((t, i) => (
              <MeterUpdater
                loading={loading}
                count={counts?.find(c => c.type === i)?.count || 0}
                key={t}
                type={i}
                callback={(type, count) => {
                  updateCount(type, count);
                }}
              />
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
}

export default MeterCountComponent