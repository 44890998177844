import { Button, Card, CardActions, CardContent, Divider, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Switch, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useAxios } from '../../hooks/useAxios';
import { ApiResponse } from '../../models/ApiResponse';
import { emptyMachine, Machine } from '../../models/Machine'

type Props = {
  machine?: Machine
  customerId?: string
  cancelAdd: (id?: string) => void
}

function MachineView(prop: Props) {

  const [isMoe, setIsMoe] = useState(0);
  const [sending, setSending] = useState(false);
  const [isContract, setIsContract] = useState(false);
  const _machine = prop.machine ?? emptyMachine(prop.customerId || '');

  const [machine, setMachine] = useState(_machine);

  useEffect(() => {
    setMachine(_machine);
  }, [_machine])

  const axios = useAxios();

  useEffect(() => {
    if (!machine.id) {
      setIsMoe(2);
    }
  }, [machine])

  useEffect(() => {
    if(isMoe == 2) {
      setIsContract(machine.contract);
    }
  }, [isMoe])
  
  return (
    <div style={{ display: "block", margin: "1em" }}>
      <Card>
        <CardContent>
          <form noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item md={6} sm={12}>
                <TextField
                  fullWidth
                  sx={{ mb: 1 }}
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    setMachine({ ...machine, serial: e.target.value || "" });
                  }}
                  value={machine.serial}
                  label="Serial #"
                  disabled={isMoe == 0}
                />
              </Grid>
              <Grid item md={6} sm={12}>
                <TextField
                  fullWidth
                  sx={{ mb: 1 }}
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    setMachine({
                      ...machine,
                      external_id: e.target.value || "",
                    });
                  }}
                  value={machine.external_id}
                  label="Equipment Id"
                  disabled={isMoe == 0}
                />
              </Grid>
              {/* <Grid item md={6} sm={12}>
                <TextField
                  fullWidth
                  sx={{ mb: 1 }}
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    setMachine({ ...machine, brand: e.target.value || "" });
                  }}
                  value={machine.brand}
                  label="Brand"
                  disabled={isMoe == 0}
                />
              </Grid>
              <Grid item md={6} sm={12}>
                <TextField
                  fullWidth
                  sx={{ mb: 1 }}
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    setMachine({ ...machine, make: e.target.value || "" });
                  }}
                  value={machine.make}
                  label="Make"
                  disabled={isMoe == 0}
                />
              </Grid> */}
              <Grid item md={6} sm={12}>
                <TextField
                  fullWidth
                  sx={{ mb: 1 }}
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    setMachine({ ...machine, model: e.target.value || "" });
                  }}
                  value={machine.model}
                  label="Model"
                  disabled={isMoe == 0}
                />
              </Grid>
              <Grid item md={6} sm={12}>
                <FormControlLabel
                  control={
                    <Switch
                      onChange={(e) => {
                        setMachine({ ...machine, contract: e.target.checked });
                        setIsContract(e.target.checked);
                      }}
                      disabled={isMoe == 0}
                      checked={machine.contract}
                    />
                  }
                  label="Under Contract?"
                />
              </Grid>
              {/* <Grid item md={6} sm={12}>
                <FormControl fullWidth sx={{ mb: 1 }}>
                  <InputLabel>Contract Level</InputLabel>
                  <Select
                    value={machine.contract_level || ""}
                    onChange={(e) => {
                      setMachine({ ...machine, contract_level: e.target.value || "" });
                    }}
                    label="Contract Level"
                    disabled={!isContract}
                    >
                    <MenuItem value="">None</MenuItem>
                    <MenuItem value="CONTRACT">CONTRACT</MenuItem>
                    <MenuItem value="CHARGE">CHARGE</MenuItem>
                  </Select>
                </FormControl>
              </Grid> */}
              <Grid item md={6} sm={12}>
                <TextField
                  fullWidth
                  sx={{ mb: 1 }}
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    setMachine({ ...machine, contract_level: e.target.value || "" });
                  }}
                  value={machine.contract_level}
                  label="Contract Level"
                  disabled={!isContract}
                />
              </Grid>

              <Grid item md={6} sm={12}>
                <TextField
                  fullWidth
                  sx={{ mb: 1 }}
                  type="date"
                  autoComplete="off"
                  onChange={(e) => {
                    setMachine({ ...machine, expiry_date: e.target.value || "" });
                  }}
                  value={machine.expiry_date || ""}
                  label="Expiry Date"
                  disabled={!isContract}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Divider />
              <Grid item md={6} sm={12}>
                <TextField
                  fullWidth
                  sx={{ mb: 1 }}
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    setMachine({
                      ...machine,
                      contact_name: e.target.value || "",
                    });
                  }}
                  value={machine.contact_name}
                  label="Contact Name"
                  disabled={isMoe == 0}
                />
              </Grid>
              <Grid item md={6} sm={12}>
                <TextField
                  fullWidth
                  sx={{ mb: 1 }}
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    setMachine({
                      ...machine,
                      contact_number: e.target.value || "",
                    });
                  }}
                  value={machine.contact_number}
                  label="Contact Number"
                  disabled={isMoe == 0}
                />
              </Grid>
              <Grid item md={6} sm={12}>
                <TextField
                  fullWidth
                  sx={{ mb: 1 }}
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    setMachine({
                      ...machine,
                      address: e.target.value || "",
                    });
                  }}
                  value={machine.address}
                  label="Address"
                  disabled={isMoe == 0}
                />
              </Grid>
              <Grid item md={4} sm={12}>
                <TextField
                  fullWidth
                  sx={{ mb: 1 }}
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    setMachine({
                      ...machine,
                      city: e.target.value || "",
                    });
                  }}
                  value={machine.city}
                  label="City"
                  disabled={isMoe == 0}
                />
              </Grid>
              <Grid item md={4} sm={12}>
                <TextField
                  fullWidth
                  sx={{ mb: 1 }}
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    setMachine({
                      ...machine,
                      state: e.target.value || "",
                    });
                  }}
                  value={machine.state}
                  label="State"
                  disabled={isMoe == 0}
                />
              </Grid>
              <Grid item md={4} sm={12}>
                <TextField
                  fullWidth
                  sx={{ mb: 1 }}
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    setMachine({
                      ...machine,
                      zip: e.target.value || "",
                    });
                  }}
                  value={machine.zip}
                  label="Zip"
                  disabled={isMoe == 0}
                />
              </Grid>
              <Grid item md={4} sm={12}>
                <TextField
                  fullWidth
                  sx={{ mb: 1 }}
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    setMachine({
                      ...machine,
                      onsite_location: e.target.value || "",
                    });
                  }}
                  value={machine.onsite_location}
                  label="Onsite Location"
                  disabled={isMoe == 0}
                />
              </Grid>
            </Grid>
          </form>
        </CardContent>
        <CardActions>
          {isMoe == 2 && (
            <>
              <Button
                disabled={sending}
                size="small"
                variant="contained"
                color="success"
                onClick={() => {

                  const validateExpiryDate = () => {
                    const currentDate = new Date().setHours(0, 0, 0, 0);
                    const expiryDate = new Date(machine.expiry_date).setHours(0, 0, 0, 0);
              
                    if (expiryDate < currentDate && isContract) {
                      alert("Expiry date must not be earlier than today.");
                      return false;
                    }
                    return true;
                  };
              
                  // Check expiry date before proceeding
                  if (!validateExpiryDate()) {
                    return;
                  }

                  setSending(true);

                  axios
                    .post<ApiResponse<Machine>>(
                      "machines/addorupdate",
                      JSON.stringify(machine)
                    )
                    .then((res) => {
                      const data = res.data;
                      alert(data.message);
                      setIsMoe(0);
                      setIsContract(false);
                    })
                    .finally(() => {
                      setSending(false);
                    });
                }}
              >
                Save
              </Button>
              <Button
                disabled={sending}
                size="small"
                variant="outlined"
                color="primary"
                onClick={() => {
                  setIsMoe(0);
                  setIsContract(false);
                  if (!machine.id) {
                    prop.cancelAdd();
                  }
                }}
              >
                Cancel
              </Button>
            </>
          )}
          {isMoe != 2 && (
            <>
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={() => setIsMoe(2)}
                disabled={sending}
              >
                Edit
              </Button>
              <Button
                disabled={sending}
                size="small"
                variant="outlined"
                color="primary"
                onClick={() => {
                  // eslint-disable-next-line no-restricted-globals
                  if (confirm("Delete this machine?")) {
                    setSending(true);

                    axios
                      .delete<ApiResponse<Machine>>(
                        `machines?machineId=${machine.id}`
                      )
                      .then((res) => {
                        const data = res.data;
                        alert(data.message);
                        prop.cancelAdd(machine.id);
                      })
                      .finally(() => {
                        setSending(false);
                      });
                  }
                }}
              >
                Delete
              </Button>
            </>
          )}
        </CardActions>
      </Card>
    </div>
  );
}

export default MachineView
