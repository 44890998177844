import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {  Box, FormControlLabel, Grid, InputLabel, MenuItem, Select, Switch, Tab, Tabs, TextField, Typography } from '@mui/material';
import { useAxios } from '../../hooks/useAxios';
import { AxiosError } from 'axios';
import { ApiResponse } from '../../models/ApiResponse';
import { showToast } from '../../components/Toaster';
import { Customer, CustomerWithMachine, emptyCustomer } from '../../models/Customers';
import { Machine } from '../../models/Machine';
import Machines from './Machines';
import { TabPanel } from '../../components/TabPanel';
import { SERVICE_CALL_DIVISION_ENUM } from '../../models/ServiceCall';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));



export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

let customerModalOpen = (customer:CustomerWithMachine) => {};
let customerModalClose = () => {};

type CustomerDialogProps = {
    onClose?: () => void;

}

export default function CustomizedDialogs({ onClose }: CustomerDialogProps) {
  const [open, setOpen] = React.useState(false);
  const [ _customer, setCustomer ] = React.useState<Customer>(emptyCustomer());
  const [ _machines, setMachines ] = React.useState<Machine[] | undefined>();
  const [loading, setLoading] = React.useState(false);
  const [isAdd, setIsAdd] = React.useState(false)

  const [tabValue, setTabValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const axios = useAxios();

  customerModalOpen = (customer:CustomerWithMachine) => {
    setCustomer(customer);
    setMachines(customer.machines);
    setIsAdd(customer.id === undefined)
    setTabValue(0)
    setOpen(true);
  };
  customerModalClose = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  };

  

  return (
    <div>
      <BootstrapDialog
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            return false;
          }
        }}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "800px", // Set your width here
            },
          },
        }}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={customerModalClose}
        >
          {isAdd ? "Add" : "Edit"} Customer
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Tabs value={tabValue} onChange={handleChange}>
            <Tab label="Primary info" />
            <Tab label="Machines" disabled={isAdd} />
          </Tabs>
          <TabPanel value={tabValue} index={0}>
            <Box component="form" noValidate autoComplete="off">
              <Grid container spacing={1}>
                <Grid item sm={12}>
                  <InputLabel id="call-division">Division</InputLabel>
                  <Select
                    labelId="call-division"
                    value={_customer.division}
                    fullWidth
                    onChange={(e) => {
                      setCustomer({
                        ..._customer,
                        division: e.target.value as number,
                      });
                    }}
                  >
                    {SERVICE_CALL_DIVISION_ENUM.map((e, i) => (
                      <MenuItem key={i} value={i}>
                        {e}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item sm={6}>
                  <Typography variant="subtitle1" sx={{ mb: 1 }}>
                    Customer
                  </Typography>
                  <TextField
                    fullWidth
                    className="my-2"
                    id="customer-name"
                    type="text"
                    autoComplete="off"
                    onChange={(e) => {
                      setCustomer({ ..._customer, name: e.target.value || "" });
                    }}
                    value={_customer?.name}
                    label="Customer/Client name"
                  />
                </Grid>
                <Grid item sm={6}>
                  <Typography variant="subtitle1" sx={{ mb: 1 }}>
                    &nbsp;
                  </Typography>
                  <TextField
                    fullWidth
                    className="my-2"
                    id="customer-name"
                    type="text"
                    autoComplete="off"
                    onChange={(e) => {
                      setCustomer({
                        ..._customer,
                        external_id: e.target.value || "",
                      });
                    }}
                    value={_customer?.external_id}
                    label="QB Id"
                  />
                </Grid>
                <Grid item sm={4}>
                  <TextField
                    fullWidth
                    className="my-2"
                    id="contact-name"
                    type="text"
                    autoComplete="off"
                    onChange={(e) => {
                      setCustomer({
                        ..._customer,
                        contact_name: e.target.value || "",
                      });
                    }}
                    value={_customer?.contact_name}
                    label="Contact name"
                  />
                </Grid>
                <Grid item sm={4}>
                  <TextField
                    fullWidth
                    className="my-2"
                    id="contact-email"
                    type="email"
                    autoComplete="off"
                    onChange={(e) => {
                      setCustomer({
                        ..._customer,
                        contact_email: e.target.value || "",
                      });
                    }}
                    value={_customer?.contact_email}
                    label="Contact email"
                  />
                </Grid>
                <Grid item sm={4}>
                  <TextField
                    fullWidth
                    className="my-2"
                    id="contact-number"
                    type="tel"
                    autoComplete="off"
                    onChange={(e) => {
                      setCustomer({
                        ..._customer,
                        contact_number: e.target.value || "",
                      });
                    }}
                    value={_customer?.contact_number}
                    label="Contact number"
                  />
                </Grid>
                <Grid item sm={12}>
                  <hr />
                  <Typography variant="subtitle1" sx={{ mt: 2 }}>
                    Address
                  </Typography>
                </Grid>
                <Grid item sm={4}>
                  <TextField
                    fullWidth
                    className="my-2"
                    id="address"
                    type="text"
                    autoComplete="off"
                    onChange={(e) => {
                      setCustomer({
                        ..._customer,
                        address: e.target.value || "",
                      });
                    }}
                    value={_customer?.address}
                    label="Street Address"
                  />
                </Grid>
                <Grid item sm={3}>
                  <TextField
                    fullWidth
                    className="my-2"
                    id="City"
                    type="text"
                    autoComplete="off"
                    onChange={(e) => {
                      setCustomer({
                        ..._customer,
                        city: e.target.value || "",
                      });
                    }}
                    value={_customer?.city}
                    label="City"
                  />
                </Grid>
                <Grid item sm={3}>
                  <TextField
                    fullWidth
                    className="my-2"
                    id="state"
                    type="text"
                    autoComplete="off"
                    onChange={(e) => {
                      setCustomer({
                        ..._customer,
                        state: e.target.value || "",
                      });
                    }}
                    value={_customer?.state}
                    label="State"
                  />
                </Grid>
                <Grid item sm={2}>
                  <TextField
                    fullWidth
                    className="my-2"
                    id="zip"
                    type="text"
                    autoComplete="off"
                    onChange={(e) => {
                      setCustomer({
                        ..._customer,
                        zip: e.target.value || "",
                      });
                    }}
                    value={_customer?.zip}
                    label="Zip"
                  />
                </Grid>
              </Grid>
            </Box>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <Machines machines={_machines} customerId={_customer.id} />
          </TabPanel>
        </DialogContent>
        <DialogActions>
          <FormControlLabel
            control={
              <Switch
                onChange={(e) => {
                  setCustomer({ ..._customer, hold: e.target.checked });
                }}
                checked={_customer.hold}
                color={_customer.hold ? "error" : "default"}
              />
            }
            label={
              <Typography color={_customer.hold ? "red" : "green"}>
                On Hold?
              </Typography>
            }
          />
          <Button
            disabled={loading}
            onClick={() => {
              setLoading(true);
              axios
                .post<ApiResponse<Customer>>(
                  "customers/addorupdate",
                  JSON.stringify(_customer)
                )
                .then((response) => {
                  const apiResponse = response.data;

                  if (apiResponse.success) {
                    showToast({
                      message: "Customer saved!",
                      severity: "success",
                    });
                  } else {
                    showToast({
                      message: "An error occurred!",
                      severity: "error",
                    });
                  }
                })
                .catch((reason: AxiosError) => {
                  showToast({
                    message: `An Error occurred: ${reason.code}`,
                    severity: "error",
                  });
                })
                .finally(() => {
                  setLoading(false);
                  customerModalClose();
                });
            }}
          >
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

export { customerModalClose, customerModalOpen }
