import React, { useState, useEffect } from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody, Typography, Box } from '@mui/material';
import { FormattedServiceCall } from '../models/ServiceCall';
import moment from 'moment';

type OSCAgingSummaryProps = {
  title: string;
  period: string;
  serviceList: FormattedServiceCall[];
};

const OSCAgingSummary: React.FC<OSCAgingSummaryProps> = ({ title, period, serviceList }) => {
  const [technicianReport, setTechnicianReport] = useState<Record<string, { [key: string]: number }>>({});

  useEffect(() => {
    const currentDate = moment();
    const initialTechnicianReport: Record<string, { [key: string]: number }> = {};

    serviceList.forEach(call => {
      if (![8, 9, 17].includes(Number(call.call.status))) {
        const createdDate = moment(call.call.created);
        if (!createdDate.isValid()) {
          console.warn(`Invalid date for call ID ${call.call.external_id}:`, call.call.created);
          return; // Skip this iteration if the date is invalid
        }

        const daysOpen = currentDate.diff(createdDate, 'days');
        let bracket = '';

        // Bracket assignment
        if (daysOpen <= 7) {
          bracket = '1-7 Days';
        } else if (daysOpen <= 15) {
          bracket = '8-15 Days';
        } else if (daysOpen <= 22) {
          bracket = '16-22 Days';
        } else if (daysOpen <= 30) {
          bracket = '23-30 Days';
        } else {
          bracket = 'Over 30 Days';
        }

        const techName = call.assigned_to?.name || 'Unknown Technician';
        if (!initialTechnicianReport[techName]) {
          initialTechnicianReport[techName] = {
            '1-7 Days': 0,
            '8-15 Days': 0,
            '16-22 Days': 0,
            '23-30 Days': 0,
            'Over 30 Days': 0,
            'Total Calls': 0,
          };
        }

        initialTechnicianReport[techName][bracket]++;
        initialTechnicianReport[techName]['Total Calls']++;
      }
    });

    setTechnicianReport(initialTechnicianReport);
  }, [serviceList]); // Add serviceList as a dependency to avoid stale data

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography variant="h5" sx={{ fontWeight: 'bold', mt: 3, mb: 1, textAlign: 'center' }}>
        {title}
      </Typography>
      <Typography variant="subtitle1" sx={{ mb: 2, textAlign: 'center' }}>
        {period}
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold' }}>Technician Name</TableCell>
            <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>1-7 Days</TableCell>
            <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>8-15 Days</TableCell>
            <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>16-22 Days</TableCell>
            <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>23-30 Days</TableCell>
            <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>Over 30 Days</TableCell>
            <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>Total Calls</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(technicianReport).map(([techName, bracketSummary]) => (
            <TableRow key={techName}>
              <TableCell>{techName}</TableCell>
              <TableCell sx={{ textAlign: 'center' }}>{bracketSummary['1-7 Days']}</TableCell>
              <TableCell sx={{ textAlign: 'center' }}>{bracketSummary['8-15 Days']}</TableCell>
              <TableCell sx={{ textAlign: 'center' }}>{bracketSummary['16-22 Days']}</TableCell>
              <TableCell sx={{ textAlign: 'center' }}>{bracketSummary['23-30 Days']}</TableCell>
              <TableCell sx={{ textAlign: 'center' }}>{bracketSummary['Over 30 Days']}</TableCell>
              <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}>{bracketSummary['Total Calls']}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default OSCAgingSummary;
