import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {  
  Chip, 
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow 
} from '@mui/material';
import { 
  FormattedServiceCall,
  serviceCallDivisionDescription,
  serviceCallTypeDescription
} from '../../models/ServiceCall';
import { FaEdit } from 'react-icons/fa'
import ServiceCallDialog, { serviceCallModalOpen } from '../admin/ServiceCallDialog'


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2}} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

let serviceHistoryModalOpen = (machineMongoID:string, serviceHistory:FormattedServiceCall[]) => {};
let serviceHistoryModalClose = () => {};

type ServiceHistoryListProps = {
  onClose?: () => void;
}

export default function CustomizedDialogs({ onClose }: ServiceHistoryListProps) {
  //states
  const [open, setOpen] = React.useState(false);
  const [recievedServiceHistory, setRecievedServiceHistory] = React.useState<FormattedServiceCall[]>([] as FormattedServiceCall[])
  const [currentMachineID, setcurrentMachineID] = React.useState("");
  const [filteredServiceHistory, setFilteredServiceHistory] = React.useState<FormattedServiceCall[]>([] as FormattedServiceCall[])
  
  //update states based on changes
  useEffect(() =>{
    let sh = recievedServiceHistory.filter(c => {
      if(!c.machine)
        return false
      return c.machine.id?.includes(currentMachineID);
    })
    setFilteredServiceHistory([...sh])

  }, [currentMachineID , recievedServiceHistory])
  
  //handle openning and closing of modal
  serviceHistoryModalOpen = (machineMongoID:string, serviceHistory:FormattedServiceCall[]) => {
    setOpen(true)

    let mmID = machineMongoID
    setcurrentMachineID(mmID)
    
    let sh = serviceHistory
    setRecievedServiceHistory([...sh]);
  }

  serviceHistoryModalClose = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  }

  return (
    <div>
      <BootstrapDialog
        onClose = {(_, reason) => {
          if (reason !== "backdropClick") {
            return false;
          }
        }}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "800px",
            },
          },
        }}

        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={serviceHistoryModalClose}
        >
          Service History
        </BootstrapDialogTitle>
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Call Data</TableCell>
                <TableCell>Assigned To</TableCell>
                <TableCell>Customer / Machine info</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredServiceHistory.length < 1 && (
                <>
                  <TableRow>
                    <TableCell colSpan={5} sx={{ textAlign: "center" }}>
                      - No Data -
                    </TableCell>
                  </TableRow>
                </>
              )}
              {filteredServiceHistory.slice(0).reverse().map((dr) => (
                <TableRow key={dr.id}>
                  <TableCell>
                    <Chip
                      size="small"
                      color="success"
                      label={serviceCallDivisionDescription(dr.call.division)}
                    />
                    {dr.call.is_callback && (
                      <Chip
                        sx={{ ml: 1 }}
                        size="small"
                        color="warning"
                        label="Callback"
                      />
                    )}
                    {dr.customer?.hold && (
                      <Chip
                        sx={{ ml: 1 }}
                        size="small"
                        color="error"
                        label="On Hold"
                      />
                    )}
                    {dr.call?.vip && (
                      <Chip
                        sx={{ ml: 1 }}
                        size="small"
                        color="secondary"
                        label="VIP"
                      />
                    )}
                    <div>
                      <b>ID:</b>
                      <span style={{ fontFamily: "monospace" }}>
                        {" "}
                        {dr.call.external_id || dr.id}
                      </span>
                    </div>
                    <div>
                      <b>Created:</b>{" "}
                      <span style={{ fontFamily: "monospace" }}>
                        {dr.created} ({dr.time_since_creation})
                      </span>
                    </div>
                    <div>
                      <b>Created By:</b>{" "}
                      <span style={{ fontFamily: "monospace" }}>
                        {dr.created_by_name}
                      </span>
                    </div>
                  </TableCell>
                  <TableCell>{dr.assigned_to?.name ?? "---"}</TableCell>
                  <TableCell>
                    <div>
                      {dr.machine?.contract && (
                        <Chip
                          sx={{ ml: 1 }}
                          size="small"
                          color="warning"
                          label="Contract"
                        />
                      )}
                    </div>
                    <div>
                      <u><i><b>{dr.customer?.name}</b></i></u>
                      <br />
                      <b>Call Type: </b>
                      <span
                        style={{
                          color: dr.call.call_type === 3 ? "red" : "inherit",
                          fontWeight:
                            dr.call.call_type === 3 ? "bold" : "inherit",
                        }}
                      >
                        {serviceCallTypeDescription(dr.call.call_type ?? -1)}
                      </span>
                    </div>
                    <div>
                      <b>Equipment ID:</b>{" "}
                      <span style={{ fontFamily: "monospace" }}>
                        {dr.machine?.external_id}
                      </span>
                    </div>
                    <div>
                      <b>Machine:</b>{" "}
                      <span style={{ fontFamily: "monospace" }}>
                      {dr.machine?.model}
                      <br />
                      {dr.machine?.brand} {dr.machine?.make}
                      </span>
                    </div>
                    <div>
                      <b style={{ display: "block" }}>Description:</b>
                      <span
                        style={{
                          fontFamily: "monospace",
                          backgroundColor: "#eee",
                          padding: "5px",
                        }}
                      >
                        {dr.description}
                      </span>
                    </div>
                    <div>
                      <b>Machine Location: </b>
                      {dr.machine?.address}, {dr.machine?.city},{" "}
                      {dr.machine?.state}
                    </div>
                  </TableCell>
                  <TableCell>
                    {dr.status}
                    <br />({dr.time_since_last_update})
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => {
                        serviceCallModalOpen(dr.call);
                      }}
                    >
                      <FaEdit />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
      </BootstrapDialog>

      <ServiceCallDialog onClose={() => {}} />
    </div>
  )
};

export { serviceHistoryModalClose, serviceHistoryModalOpen }