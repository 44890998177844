import { Alert, Snackbar } from '@mui/material';
import React from 'react'



export type ShowToastProps = {
    message: string,
    severity: "success" | "error"
}

let showToast = (props: ShowToastProps) => {};


const Toaster = () => {
  const [snackbarShown, setSnackbarShown] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState<
    "success" | "error"
  >("success");

  showToast = (props: ShowToastProps) => {
    setSnackbarMessage(props.message)
    setSnackbarSeverity(props.severity);
    setSnackbarShown(true);
  };

  return (
    <div>
      <Snackbar open={snackbarShown} autoHideDuration={5000}  onClose={() => setSnackbarShown(false) }>
        <Alert severity={snackbarSeverity} sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Toaster

export { showToast } 