import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { restoreAuth } from '../context/AuthProvider';
import { useAuth } from '../hooks/useAuth';

type Props = {
    admin?: boolean
}

export const RequireAuth = ({ admin }: Props) => {
    const { auth } = useAuth();
    const location = useLocation();
    const tokencheck = auth?.token !== undefined && auth?.token !== '';

    let authToCheck = auth;

    let failedInitialCheck = false;

    if (!tokencheck) {

        failedInitialCheck = true;

        const restored = restoreAuth();

        //console.log(restored);

        if (!restored) {
            return  <Navigate to="/" state={{ from: location }} replace />;
        }

        authToCheck = restored;
        
    }

    if (!admin) {
        return <Outlet />
    }

    if (authToCheck?.access !== 'Admin' && authToCheck?.access !== 'SuperAdmin') {
        return  <Navigate to="/unauthorized" state={{ from: location }} replace />;
    }
    
    if (failedInitialCheck) {
        return  <Navigate to="/admin" state={{ from: location }} replace />;
    }

    return <Outlet />
    
};
