import { Button, Divider, Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useCallback, useEffect, useState } from 'react'
import { FaEdit, FaTrash } from 'react-icons/fa';
import { useAxios } from '../hooks/useAxios';
import { ApiResponse } from '../models/ApiResponse';
import { CallItem, callItemCategory, emptyCallItem, getItemName } from '../models/ServiceCall';
import CallItemModal, { callItemModalOpen } from '../pages/shared/CallItemDialog';
// added by alvin
// import divisionData from "../data/divisionData.json";

type Props = {
    callId: string;
    contract: boolean;
    division: number;
}

function CallItemsControl({ callId, contract, division }: Props) {
    const [items, setItems] = useState<CallItem[]|undefined>(undefined)
    const [loading, setIsLoading] = useState(false)
    const axios = useAxios();
    
    const fetchItems = useCallback(() => {
        setIsLoading(true)
        axios
        .get<ApiResponse<CallItem[]>>(`callitem/get?id=${callId}`)
        .then((res) => {
          const responseData = res.data;
          if (responseData.success) {
            setItems(responseData.data);
          }
        })
        .finally(() => {
            setIsLoading(false);
        });
      },[axios, callId])

    useEffect(() => {

        if (!items)
        fetchItems();

        console.log("LOADED")
        
    }, [fetchItems, items, division])
    
    // Modified by alvin to cater USD and PHP
    const numberFormat = (value:number) =>
    new Intl.NumberFormat(division === 5 ? 'en-PH' : 'en-US', {
        style: 'currency',
        currency: division === 5 ? 'PHP' : 'USD'
    }).format(value);     
    
    if (loading) return <>Loading....</>

    return (
      <>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>&nbsp;</Grid>
          <Grid item><Button variant="contained" onClick={() => {
            callItemModalOpen(emptyCallItem(callId), contract, division);
          }}>Add Item</Button></Grid>
        </Grid>
        
        <Divider />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ITEM</TableCell>
              <TableCell>QTY</TableCell>
              <TableCell>AMT</TableCell>
              <TableCell>DESC</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items &&
              items.map((item) => (
                <TableRow key={item.id}>
                  {/* <TableCell>{callItemCategory(item.category)}</TableCell> */}
                  <TableCell>{getItemName(division, item.category_id, item.item_id)}</TableCell>
                  <TableCell>{item.quantity}</TableCell>
                  <TableCell style={{textAlign: 'right'}}>{numberFormat(item.amount)}</TableCell>
                  <TableCell>{item.description}</TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="Edit"
                      onClick={() => {
                        callItemModalOpen(item, contract, division);
                      }}
                    >
                      <FaEdit />
                    </IconButton>
                    <IconButton aria-label="Delete" onClick={async () => {
                        // eslint-disable-next-line no-restricted-globals
                        if (confirm("Are you sure?")) {
                           await axios.delete(`callitem/delete?id=${item.id}`)
                            alert("Done!")
                            fetchItems();
                        }
                    }}>
                      <FaTrash style={{ color: '#e15b5b'}} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>

        <CallItemModal onClose={() => { fetchItems() }} />
      </>
    );
}

export default CallItemsControl