import React, { useMemo, useState } from 'react';
import { FormattedServiceCall, serviceCallStatusDescription } from '../models/ServiceCall';
import { Table, TableHead, TableBody, TableCell, TableRow, Typography, Box } from '@mui/material';

type StatusCount = {
    [status: string]: number;
};

type TechnicianGroup = {
    total: number;          
    statuses: StatusCount; 
};

type GroupedServiceCalls = {
    [technician: string]: TechnicianGroup;
};

// Function to group service calls by technician and status
const groupServiceCalls = (serviceCalls: FormattedServiceCall[]): GroupedServiceCalls => {
    const grouped: GroupedServiceCalls = {};

    serviceCalls.forEach(call => {
        const techName = call.assigned_to?.name || 'Unknown Technician';
        const status = call.call.status
        console.log('group status ' + call.call.status)
        // Initialize technician group if it doesn't exist
        if (!grouped[techName]) {
            grouped[techName] = { total: 0, statuses: {} };
        }

        // Increment total for technician
        grouped[techName].total += 1;

        // Initialize status group if it doesn't exist
        if (!grouped[techName].statuses[status]) {
            grouped[techName].statuses[status] = 0;
        }

        // Increment status count
        grouped[techName].statuses[status] += 1;
    });

    return grouped;
};

type ServicePerformanceSummaryProps = {
    title: string;
    period: string;
    serviceList: FormattedServiceCall[];
};

const ServicePerformanceSummary: React.FC<ServicePerformanceSummaryProps> = ({ title, period, serviceList }) => {

    const groupedServiceCalls = useMemo(() => groupServiceCalls(serviceList), [serviceList]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="h5" sx={{ fontWeight: 'bold', mt: 3, mb: 1, textAlign: 'center' }}>
                {title}
            </Typography>
            <Typography variant="subtitle1" sx={{ mb: 2, textAlign: 'center' }}>
                {period}
            </Typography>
            <Table sx={{ width: '80%' }}>
                <TableHead>
                    <TableRow sx={{ backgroundColor: (theme) => theme.palette.action.hover }}>
                        <TableCell sx={{ fontWeight: 'bold' }}>Technician</TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }}>Status</TableCell>
                        <TableCell></TableCell>
                        <TableCell align="center" sx={{ fontWeight: 'bold' }}>Count</TableCell>
                        <TableCell align="center" sx={{ fontWeight: 'bold' }}>Percent</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.entries(groupedServiceCalls).map(([techName, group]) => (
                        <>
                            {/* Row for technician name and total count */}
                            <TableRow key={techName}>
                                <TableCell sx={{ fontWeight: 'bold' }}>{techName}</TableCell>
                                <TableCell colSpan={2}></TableCell>
                                <TableCell align="center" sx={{ fontWeight: 'bold' }}>{group.total}</TableCell>
                                <TableCell align="center" sx={{ fontWeight: 'bold' }}>{'100.00%'}</TableCell>
                            </TableRow>

                            {/* Rows for each status under the technician */}
                            {Object.entries(group.statuses).map(([status, count]) => {
                                console.log(`Status: ${status}, Count: ${count}, Percentage: ${(count / group.total) * 100}%`);

                                return (
                                    <TableRow key={`${techName}-${status}`}>
                                        <TableCell></TableCell>
                                        <TableCell>{serviceCallStatusDescription(Number(status))}</TableCell>
                                        <TableCell></TableCell>
                                        <TableCell align="center">{count}</TableCell>
                                        <TableCell align="center">{((count / group.total) * 100).toFixed(2) + '%'}</TableCell>
                                    </TableRow>
                                );
                            })}

                        </>
                    ))}
                </TableBody>
            </Table>
        </Box>
    );
};

export default ServicePerformanceSummary;
