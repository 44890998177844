import React, { useMemo, useState } from 'react';
import { useAuth } from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import { Axios } from 'axios';
import { showToast } from '../components/Toaster';
import { clearAuth, saveAuth } from '../context/AuthProvider';
import { useDataProvider } from '../hooks/useDataProvider';

function Login() {
  
  const { setAuth } = useAuth();
  const navigate = useNavigate();


  //const tempToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2MmVjZDgxYzRmNDRiZjg0MTg0ZWRjYTMiLCJqdGkiOiI2MmVjZDgxYzRmNDRiZjg0MTg0ZWRjYTMiLCJFbWFpbCI6InByaW5jZS5jaGF2ZXowQGdtYWlsLmNvbSIsIkd1aWQiOiI2MmVjZDgxYzRmNDRiZjg0MTg0ZWRjYTMiLCJOYW1lIjoiUHJpbmNlIENoYXZlejIiLCJBY2Nlc3MiOiJTdXBlckFkbWluIiwiZXhwIjoxNjYxMjE2NTM4LCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo0NDMxMC8iLCJhdWQiOiJodHRwczovL2xvY2FsaG9zdDo0NDMxMC8ifQ.ALBj20ATBwumMyw37CHDw6s1XlY4B-jPkzyIC4SPzvQ'
  const [isLoading, setIsLoading] = useState(false);
  const [credentials, setCredentials] = useState({ email: '', password: ''})

  const { refetchAll } = useDataProvider();

  const defaultAxios = () => {
      return new Axios({
        baseURL: "/api/",
        headers: {
          "Content-Type": "application/json"
        },
        responseType: "json",
        transformResponse: (data) => (data ? JSON.parse(data) : undefined), 
      });


  }

  interface AuthResponse {
    token: string;
    email: string;
    guid: string;
    access: 'Admin' | 'Technician' | 'SuperAdmin' | undefined;
  }
  const validateEmail = (email: string) => {
      return String(email)
          .toLowerCase()
          .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  };

  useMemo(() => {
    clearAuth()
  }, [])

  return (
    <>
      <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div className="text-center">
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
          </div>
          <form
            className="mt-8 space-y-6"
            method="POST"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                disabled={isLoading}
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  value={credentials.email}
                  onChange={(e) => {
                    setCredentials(oldcreds => ({ ...oldcreds, email: e.target.value }))
                  }}
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Email address"
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  disabled={isLoading}
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Password"
                  value={credentials.password}
                  onChange={(e) => {
                    setCredentials(oldcreds => ({ ...oldcreds, password: e.target.value }))
                  }}
                />
              </div>
            </div>

            <div>
              <Button
                type="submit"
                fullWidth
                disabled={isLoading}
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={(e) => {

                  setIsLoading(true)

                  defaultAxios()
                    .post<AuthResponse>('auth/login', JSON.stringify({user: credentials.email, pass: credentials.password}))
 
                    .then(res => {
                      const data = res.data;

                      if (data.token) {
                        if (setAuth) {
                          setAuth({
                            guid: data.guid,
                            email: data.email,
                            access: data.access,
                            token: data.token,
                          });

                          saveAuth({
                            guid: data.guid,
                            email: data.email,
                            access: data.access,
                            token: data.token,
                          });

                          refetchAll();
                         
                          if (data.access === 'Admin' || data.access === 'SuperAdmin'){
                            navigate('/admin', { replace: true })
                          }
                          else {
                            navigate('/tech', { replace: true })
                          }
                        }
                      } else {
                        showToast({message: 'Invalid Credentials!', severity: 'error'})
                      }
                    })
                    .finally(() => { setIsLoading(false) })

                  
                }}
              >
                Sign In
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Login;
