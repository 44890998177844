import { createTheme, ThemeProvider } from '@mui/material';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { RequireAuth } from './components/RequireAuth';
import Toaster from './components/Toaster';
import AuthProvider from './context/AuthProvider';
import DataProvider from './context/DataProvider';
import AdminMUI from './pages/admin/AdminMUI';
import Customers from './pages/admin/Customers';
import Dashboard from './pages/admin/Dashboard';
import Users from './pages/admin/Users';
import Login from './pages/Login';

import NotAuthorized from './pages/NotAuthorized';
import NotFound from './pages/NotFound';
import Tech from './pages/tech/Tech';
import Mappings from './pages/admin/Mappings';

function App() {

  const mdTheme = createTheme({
    palette: {
        primary: {
          main: '#3f51b5',
        },
        secondary: {
          main: '#f50057',
        },
      },
});

  return (
    <BrowserRouter>
      <AuthProvider>
        <ThemeProvider theme={mdTheme}>
          <DataProvider>
            <Routes>
              <Route index element={<Login />} />
              <Route path="/login" element={<Login />} />

              <Route element={<RequireAuth />}>
                <Route element={<RequireAuth admin={true} />}>
                  <Route path="/admin" element={<AdminMUI />}>
                    <Route index element={<Dashboard />} />
                    <Route path="users" element={<Users />} />
                    <Route path="customers" element={<Customers />} />
                    <Route path="mappings" element={<Mappings />} />
                  </Route>
                </Route>

                <Route path="/tech" element={<Tech />}></Route>
              </Route>
              {/* <Route path="/admin" element={<AdminMUI />}>
              <Route index element={<Dashboard />} />
              <Route path="users" element={<Users />} />
            </Route>

            <Route path="/tech" element={<Tech />}></Route> */}

              <Route path="/unauthorized" element={<NotAuthorized />}></Route>
              <Route path="*" element={<NotFound />}></Route>
            </Routes>
          </DataProvider>

          <Toaster />
        </ThemeProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
