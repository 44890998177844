import React, { useEffect, useMemo, useState } from 'react';
import { Dialog, DialogTitle, IconButton, DialogContent, Typography, Divider, FormControl, Grid, InputLabel, MenuItem, Select, TextField, TableCell, TableRow, TableHead, TableBody, Table, Button, Checkbox, ListItemText, OutlinedInput, SelectChangeEvent, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  CLOSE_STATUS_CHECK,
  FormattedServiceCall,
  SERVICE_CALL_STATUS_ENUM,
  ServiceCall,
  emptyServiceCall,
  serviceCallDivisionDescription,
  serviceCallStatusDescription,
  serviceCallTypeDescription
} from '../models/ServiceCall';
import { useDataProvider } from '../hooks/useDataProvider';
import OSCAgingSummary from '../reports/OSCAgingSummary';
import CSCAgingSummary from '../reports/CSCAgingSummary';
import ServicePerformanceSummary from '../reports/ServicePerformanceSummary';

interface DialogWindowProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  children: React.ReactNode;
}

const ServiceReportModal: React.FC<DialogWindowProps> = ({ isOpen, onClose, title, children }) => {

  const data = useDataProvider();
  // const { fetchCustomers, fetchMachines, fetchUsers, fetchServiceReports  } = useDataProvider();

  // const refresh = useCallback(() => {

  //   fetchCustomers();
  //   fetchMachines();
  //   fetchUsers();
  //   fetchServiceReports();

  // }, [fetchCustomers, fetchMachines, fetchUsers, fetchServiceReports])

  const defaultStatuses = [...Array.from(Array(SERVICE_CALL_STATUS_ENUM.length).keys())];
  const [statusFilters, setStatusFilter] = useState<number[]>([]);
  const [isSelectRangeDisabled, setSelectRangeDisabled] = useState(false);
  const [selectedTechs, setSelectedTechs] = useState<string[]>([]);
  const [isStatusToggled, setIsStatusToggled] = useState(false);
  const [isTechToggled, setIsTechToggled] = useState(false);
  const [isGenerated, setIsGenerated] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [ReportTitle, setReportTitle] = useState('');
  const [ReportPeriod, setReportPeriod] = useState('');

  const [selectedReport, setSelectedReport] = useState('');
  const [selectedRange, setSelectedRange] = useState('Today');
  const [startDate, setStartDate] = useState(moment().startOf('day').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().endOf('day').format('YYYY-MM-DD'));

  const [serviceList, setServiceList] = useState<FormattedServiceCall[]>([]);

  const [isSelectStatusDisabled, setSelectStatusDisabled] = useState(true);
  const [isSelectTechnicianDisabled, setSelectTechnicianDisabled] = useState(true);
  const [isDateFieldsDisabled, setDateFieldsDisabled] = useState(true);
  const [expanded, setExpanded] = useState<boolean>(false);

  const reports = [
    { label: 'Service Performance Summary', value: 'R1' },
    { label: 'Open Service Calls Aging Summary', value: 'R2' },
    { label: 'Completed Work Order Aging Overview', value: 'R3' },
    // { label: 'Service Productivity Report', value: 'R4' },
    // { label: 'Recurring Issues Report', value: 'R5' },
    // { label: 'Work Order Completion Report', value: 'R6' },
    // { label: 'Service Call Aging Report', value: 'R7' },
    // { label: 'Route and Travel Report', value: 'R8' },
  ];

  const ranges = [
    { label: 'Today', value: 'Today' },
    { label: 'Custom Range', value: 'Custom' },
    { label: 'Last 7 Days', value: 'last_7Days' },
    { label: 'Last 30 Days', value: 'last_30Days' },
    { label: '—', value: 'separator' }, // Separator line
    { label: 'This Week', value: 'this_Week' },
    { label: 'Last Week', value: 'last_Week' },
    { label: 'Last Two Weeks', value: 'last_Two_Weeks' },
    { label: 'Last Three Weeks', value: 'last_Three_Weeks' },
    { label: '—', value: 'separator' }, // Separator line
    { label: 'This Month', value: 'this_Month' },
    { label: 'Last Month', value: 'last_Month' },
    { label: 'Last Two Months', value: 'last_Two_Months' },
    { label: '—', value: 'separator' }, // Separator line
    { label: 'This Quarter', value: 'this_Quarter' },
    { label: 'Last Quarter', value: 'last_Quarter' },
    { label: 'Last Two Quarters', value: 'last_Two_Quarters' },
    { label: 'Last Three Quarters', value: 'last_Three_Quarters' },
    { label: '—', value: 'separator' }, // Separator line
    { label: 'This Year', value: 'this_Year' },
    { label: 'Last Year', value: 'last_Year' }
  ];

  const getDateRange = (selectedRange: React.SetStateAction<string>) => {
    let startDate, endDate;
    switch (selectedRange) {
      case 'Custom':
        startDate = moment().startOf('day');
        endDate = moment().endOf('day');
        break;
      case 'Today':
        startDate = moment().startOf('day');
        endDate = moment().endOf('day');
        break;
      case 'this_Week':
        startDate = moment().startOf('week');
        endDate = moment().endOf('week');
        break;
      case 'last_Week':
        startDate = moment().subtract(1, 'week').startOf('week');
        endDate = moment().subtract(1, 'week').endOf('week');
        break;
      case 'last_Two_Weeks':
        startDate = moment().subtract(2, 'weeks').startOf('week');
        endDate = moment().subtract(1, 'weeks').endOf('week');
        break;
      case 'last_Three_Weeks':
        startDate = moment().subtract(3, 'weeks').startOf('week');
        endDate = moment().subtract(1, 'weeks').endOf('week');
        break;
      case 'this_Month':
        startDate = moment().startOf('month');
        endDate = moment().endOf('month');
        break;
      case 'last_Month':
        startDate = moment().subtract(1, 'month').startOf('month');
        endDate = moment().subtract(1, 'month').endOf('month');
        break;
      case 'last_Two_Months':
        startDate = moment().subtract(2, 'months').startOf('month');
        endDate = moment().subtract(1, 'months').endOf('month');
        break;
      case 'this_Quarter':
        startDate = moment().startOf('quarter');
        endDate = moment().endOf('quarter');
        break;
      case 'last_Quarter':
        startDate = moment().subtract(1, 'quarter').startOf('quarter');
        endDate = moment().subtract(1, 'quarter').endOf('quarter');
        break;
      case 'last_Two_Quarters':
        startDate = moment().subtract(2, 'quarters').startOf('quarter');
        endDate = moment().subtract(1, 'quarters').endOf('quarter');
        break;
      case 'last_Three_Quarters':
        startDate = moment().subtract(3, 'quarters').startOf('quarter');
        endDate = moment().subtract(1, 'quarters').endOf('quarter');
        break;
      case 'this_Year':
        startDate = moment().startOf('year');
        endDate = moment().endOf('day');
        break;
      case 'last_Year':
        startDate = moment().subtract(1, 'year').startOf('year');
        endDate = moment().subtract(1, 'year').endOf('year');
        break;
      case 'last_7Days':
        startDate = moment().subtract(7, 'days').startOf('day');
        endDate = moment().endOf('day');
        break;
      case 'last_30Days':
        startDate = moment().subtract(30, 'days').startOf('day');
        endDate = moment().endOf('day');
        break;
      default:
        startDate = moment().startOf('week');
        endDate = moment().endOf('week');
    }

    return { startDate, endDate };
  }

  useEffect(() => {

    if (!isInitialized) {
      handleClearReport();      
      setIsInitialized(true);
    }

    if (startDate || endDate) {
      console.log('Start Date Change : ' + startDate);
      data.fetchServiceReports();
      // setIsChanged(false);
      setReportPeriod('Period Covered : ' + moment(startDate).format('MM/DD/YYYY') + ' to ' + moment(endDate).format('MM/DD/YYYY'));
      if (selectedRange === 'Custom') {
        setDateFieldsDisabled(false);
      } else {
        setDateFieldsDisabled(true);
      }
    }

  }, [isInitialized, startDate, endDate, selectedRange])

  const handleSelectRange = (e: SelectChangeEvent<string>) => {
    const selRange = e.target.value;
    const { startDate: newStartDate, endDate: newEndDate } = getDateRange(selRange);
    console.log('Selected Range : ' + selRange);
    setSelectedRange(selRange);
    setStartDate(newStartDate.format("YYYY-MM-DD"));
    setEndDate(newEndDate.format("YYYY-MM-DD"));

    data.setReportsFilter({
      ...data.reportsFilter,
      start: newStartDate?.format("YYYY-MM-DD"),
      end: newEndDate?.format("YYYY-MM-DD"),
    });

  };

  const handleAccordionChange = (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded);
  };

  const handleReportChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setSelectedReport(event.target.value);
    const title = reports.find(report => report.value === event.target.value);
    setReportTitle(title ? title.label : '');
  };

  const handleGenerateReport = () => {

    const formattedEndDate = moment(endDate);
    const formattedStartDate = moment(startDate);

    if (formattedEndDate.isBefore(formattedStartDate)) {
      alert("End date cannot be earlier than start date. Please select a valid date.");
      return;
    }

    if (selectedReport === '' || selectedReport === 'R0') {
      alert('Please select a report');
      return;
    }

    if (statusFilters.length === 0) {
      alert('Please select Status');
      return;
    }

    if (selectedTechs.length === 0) {
      alert('Please select Technician/s');
      return;
    }

    setExpanded(false);
    const sclist = getFilteredServiceReports();
    console.log('SC Data: ' + sclist);
    setServiceList(sclist);
    setIsGenerated(true);
  }

  const handleClearReport = () => {
    setSelectRangeDisabled(false);
    // setSelectedRange('Today');
    // setStartDate(moment().startOf('day').format("YYYY-MM-DD"));
    // setEndDate(moment().endOf('day').format("YYYY-MM-DD"));
    setSelectedReport('R0');
    // setIsTechToggled(false);
    // setIsStatusToggled(false);
    setDateFieldsDisabled(true);
    setSelectTechnicianDisabled(true);
    setSelectStatusDisabled(true);
    setSelectedTechs([]);
    setStatusFilter([]);
    // setServiceList([]);
  }

  const handleSelectAllTechnician = () => {
    setIsTechToggled((prev) => {
      const newToggledState = !prev;

      if (newToggledState) {
        const allTechIds = techs?.map(c => c.id).filter((id): id is string => id !== undefined) || [];
        setSelectedTechs(allTechIds);
      } else {
        setSelectedTechs([]);
      }
      return newToggledState;
    });
  };

  const handleSelectAllStatus = () => {
    setIsStatusToggled((prev) => {
      const newToggledState = !prev;
      if (newToggledState) {
        setStatusFilter(defaultStatuses.filter((_, index) => index !== 1));
      } else {
        setStatusFilter([]);
      }
      return newToggledState;
    });
  };

  const techs = useMemo(() => {
    return data.users?.filter(u => u.access_level === 0 && u.active);
  }, [data]);

  const handleStatusFilterChange = (event: SelectChangeEvent<typeof statusFilters>) => {
    const {
      target: { value },
    } = event;
    setStatusFilter(
      typeof value === 'string' ? [parseInt(value)] : value
    );
  };

  const getFilteredServiceReports = () => {

    let res = data.formattedServiceReports?.slice(); // create shallow copy

    if (selectedTechs.length > 0) {
      res = res?.filter(r => {
        return selectedTechs.includes(r.assigned_to?.id as string);
      });
    }

    if (statusFilters.length > 0) {
      res = res?.filter(c => {
        if (c.call.status === undefined) return false;
        return statusFilters.includes(c.call.status);
      });
    }

    return res ?? []; // Return filtered results
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleTechSelectChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    setSelectedTechs(typeof value === 'string' ? value.split(',') : value);
  };

  const selectCompletedCallStatuses = () => {
    setStatusFilter(defaultStatuses.filter((_, index) => index === 8 || index === 17));
  }

  const selectCanceledCallStatuses = () => {
    setStatusFilter(defaultStatuses.filter((_, index) => index === 9));
  }

  const selectOpenCallStatuses = () => {
    setStatusFilter(defaultStatuses.filter((_, index) => index !== 1 && index !== 9 && index !== 17));
  }

  useEffect(() => {

    setServiceList([]);
    setIsStatusToggled(false);
    setIsTechToggled(false);
    setIsGenerated(false);
    setExpanded(true);
    const todayStartDate = moment();
    const todayEndDate = moment();

    switch (selectedReport) {
      case 'R1':
        setSelectedRange('Today');
        setSelectRangeDisabled(false);
        setStartDate(todayStartDate.format("YYYY-MM-DD"));
        setEndDate(todayEndDate.format("YYYY-MM-DD"));
        data.setReportsFilter({
          ...data.reportsFilter,
          start: todayStartDate?.format("YYYY-MM-DD"),
          end: todayEndDate?.format("YYYY-MM-DD"),
        });
        setSelectStatusDisabled(false);
        setSelectTechnicianDisabled(false);
        handleSelectAllTechnician();
        handleSelectAllStatus();
        setSelectedTechs([]);
        setStatusFilter([]);
        break;
      case 'R2':
      case 'R3':
        const selRange = 'Custom';
        setSelectedRange(selRange);
        const newStartDate = moment().subtract(90,'days');
        setStartDate(newStartDate.format('YYYY-MM-DD'));
        setEndDate(todayEndDate.format("YYYY-MM-DD"));
        data.setReportsFilter({
          ...data.reportsFilter,
          start: newStartDate.format('YYYY-MM-DD'),
          end: todayEndDate?.format("YYYY-MM-DD"),
        });
        if(selectedReport === 'R3'){
          selectCompletedCallStatuses();
        } else {
          selectOpenCallStatuses();
        }
        handleSelectAllTechnician();
        setDateFieldsDisabled(false);
        setSelectTechnicianDisabled(true);
        setSelectStatusDisabled(true);
        setSelectRangeDisabled(true);
        break;
      default:
        setStartDate(todayStartDate.format("YYYY-MM-DD"));
        setEndDate(todayEndDate.format("YYYY-MM-DD"));
        data.setReportsFilter({
          ...data.reportsFilter,
          start: todayStartDate?.format("YYYY-MM-DD"),
          end: todayEndDate?.format("YYYY-MM-DD"),
        });
        setExpanded(false);
        break;
    }
  }, [selectedReport]);

  return (
    <Dialog
      open={isOpen}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          onClose();
        }
      }}
      disableEscapeKeyDown
      maxWidth="md"
      fullWidth
      sx={{
        '& .MuiDialog-paper': {
          width: '60%',
          maxWidth: 'none',
        },
      }}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: 'primary.main',
          justifyContent: 'space-between',
          color: 'white',
          pl: 2,
          pt: 1,
          pb: 1,
        }}
      >
        <Typography variant="h6">{title}</Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 5,
            top: 3,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {/* {children} */}
        <Grid container spacing={2} sx={{ border: '0px solid red', pt: '20px' }}>
          <Grid item xs={12} sx={{ border: '0px solid black', padding: '0px' }}>

            <Accordion style={{ width: '100%' }} expanded={expanded} onChange={handleAccordionChange}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography><b>Filters</b></Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2} sx={{ pt: '20px' }}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item sx={{ minWidth: '50%', maxWidth: '75%' }}>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel id="date-range-label">Date Range</InputLabel>
                          <Select
                            disabled={isSelectRangeDisabled}
                            labelId="date-range-label"
                            value={selectedRange}
                            label="Date Range"
                            onChange={handleSelectRange}
                            size="small"
                          >
                            {ranges.map((range, index) => (
                              range.value === 'separator' ? (
                                <Divider key={index} />
                              ) : (
                                <MenuItem key={index} value={range.value}>
                                  {range.label}
                                </MenuItem>
                              )
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item sx={{ maxWidth: '150px' }}>
                        <FormControl fullWidth>
                          <TextField
                            type="date"
                            disabled={isDateFieldsDisabled}
                            label="Start Date"
                            value={startDate}
                            onChange={(e) => {
                              setStartDate(e.target.value);
                              data.setReportsFilter({
                                ...data.reportsFilter,
                                start: e.target.value,
                              });
                            }}
                            variant="outlined"
                            size="small"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item sx={{ maxWidth: '150px' }}>
                        <FormControl fullWidth>
                          <TextField
                            type="date"
                            disabled={isDateFieldsDisabled}
                            label="End Date"
                            value={endDate}
                            onChange={(e) => {
                              setEndDate(e.target.value);
                              data.setReportsFilter({
                                ...data.reportsFilter,
                                end: e.target.value,
                              });
                            }}
                            variant="outlined"
                            size="small"
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item sx={{ minWidth: '50%', maxWidth: '75%' }}>
                        <FormControl fullWidth size='small'>
                          <InputLabel id="status-multiple-checkbox-label">Statuses</InputLabel>
                          <Select
                            labelId="status-multiple-checkbox-label"
                            id="status-multiple-checkbox"
                            multiple
                            size="small"
                            disabled={isSelectStatusDisabled}
                            value={statusFilters}
                            onChange={handleStatusFilterChange}
                            input={<OutlinedInput label="Statuses" />}
                            renderValue={(selected) =>
                              selected.map((s) => serviceCallStatusDescription(s))
                                .join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {SERVICE_CALL_STATUS_ENUM.map((name, i) => (
                              i !== 1 && <MenuItem key={name} value={i}>
                                <Checkbox checked={statusFilters.indexOf(i) > -1} />
                                <ListItemText primary={name} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item>
                        <Button variant="contained"
                          disabled={isSelectStatusDisabled}
                          color={isStatusToggled ? 'secondary' : 'primary'}
                          sx={{ textTransform: 'capitalize' }}
                          onClick={handleSelectAllStatus}>
                          {isStatusToggled ? "Clear Selection" : "Select All"}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item sx={{ minWidth: '50%', maxWidth: '75%' }}>
                        <FormControl fullWidth size="small">
                          <InputLabel id="tech-select-checkbox-label">Technicians</InputLabel>
                          <Select
                            labelId="tech-multiple-checkbox-label"
                            id="tech-multiple-checkbox"
                            multiple
                            size="small"
                            disabled={isSelectTechnicianDisabled}
                            value={selectedTechs}
                            onChange={handleTechSelectChange}
                            input={<OutlinedInput label="Technician" />}
                            renderValue={(selected) =>
                              techs && selected
                                .map((techId) => techs.find((tech) => tech.id === techId)?.name)
                                .join(", ")
                            }

                            MenuProps={MenuProps}
                          >
                            {techs && techs.map((tech) => (
                              <MenuItem key={tech.id} value={tech.id}>
                                <Checkbox checked={selectedTechs.includes(tech.id ?? "")} />
                                <ListItemText primary={tech.name} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item>
                        <Button variant="contained"
                          disabled={isSelectTechnicianDisabled}
                          color={isTechToggled ? 'secondary' : 'primary'}
                          sx={{ textTransform: 'capitalize' }}
                          onClick={handleSelectAllTechnician}>
                          {isTechToggled ? "Clear Selection" : "Select All"}
                        </Button>
                      </Grid>

                    </Grid>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            {/* <hr style={{ width: '100%', paddingRight: 2, margin: '10px 0px 0px 15px' }} /> */}
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Grid container spacing={2}>
                <Grid item sx={{ minWidth: '50%' }}>
                  <FormControl fullWidth size='small'>
                    <InputLabel id="report-select-label">Select a Report</InputLabel>
                    <Select
                      labelId="report-select-label"
                      value={selectedReport}
                      label="Select a Report"
                      onChange={handleReportChange}
                    >
                      {reports.map((report, index) =>
                        report.value === 'separator' ? (
                          <MenuItem key={index} disabled>
                            <Divider style={{ width: '100%' }} />
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              style={{ paddingLeft: '8px', fontWeight: 'bold' }}
                            >
                              {report.label}
                            </Typography>
                          </MenuItem>
                        ) : (
                          <MenuItem key={index} value={report.value}>
                            {report.label}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <Button variant="contained"
                    sx={{ textTransform: 'capitalize' }}
                    onClick={handleGenerateReport}>
                    Generate
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="contained"
                    sx={{ textTransform: 'capitalize', color: 'secondary' }}
                    onClick={handleClearReport}>
                    Clear
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* RESULT */}
        <hr style={{ width: '100%', margin: '10px 0' }} />
        <>
          {!isGenerated && (
            <Table>
              <TableHead>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell colSpan={12} sx={{ textAlign: "center" }}>
                    {selectedReport === 'R0' ? '- No report selected to display -' : 'Click Generate to display report'}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
          {isGenerated && selectedReport === 'R1' && (<ServicePerformanceSummary title={ReportTitle} period={ReportPeriod} serviceList={serviceList} />)}
          {isGenerated && selectedReport === 'R2' && (<OSCAgingSummary title={ReportTitle} period={ReportPeriod} serviceList={serviceList} />)}
          {isGenerated && selectedReport === 'R3' && (<CSCAgingSummary title={ReportTitle} period={ReportPeriod} serviceList={serviceList} />)}
        </>
      </DialogContent>
    </Dialog>
  );
};

export default ServiceReportModal;

