import { Button, Grid, TextField, Typography } from '@mui/material';
import { useCallback, useState  } from 'react'

import { useDataProvider } from '../../hooks/useDataProvider';

import { emptyCustomer } from '../../models/Customers';
import CustomerModal, { customerModalOpen } from './CustomerDialog'
import ServiceHistoryModal from '../shared/ServiceHistoryListRender'
import CustomerListRender from './CustomerListRender';

function Customers() {

  const { fetchCustomers, fetchMachines  } = useDataProvider();

  const [filter, setFilter] = useState('');
  const [filterInput, setFilterInput] = useState('');

  const refresh = useCallback(() => {
    
    fetchCustomers();
    fetchMachines();

  }, [fetchCustomers, fetchMachines])
 
  return (
    <>
      <Grid container justifyContent="space-between" spacing={24}>
        <Grid item>
          <Typography component="h2" variant="h6" color="primary" gutterBottom>
            Customers
          </Typography>
        </Grid>
        <Grid item>
          <Button
            onClick={(e) => {
              //userModalOpen(emptyUser())
              customerModalOpen(emptyCustomer());
            }}
            variant="contained"
          >
            Add Customer
          </Button>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={4}>
          <TextField
            label="Customer Search"
            value={filterInput}
            onChange={(e) => {
              setFilterInput(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setFilter(filterInput);
              }
            }}
          />
        </Grid>
      </Grid>
      <hr style={{ margin: "1rem" }} />
      <CustomerListRender  filter={filter} />
      <CustomerModal onClose={refresh} />
      <ServiceHistoryModal onClose={refresh} />
    </>
  );
}

export default Customers