import { Table, TableBody, TableCell, TableHead, TableRow, Typography, Button, IconButton, Grid, Paper, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import { FaUserEdit } from 'react-icons/fa'

import { emptyUser } from '../../models/User'
import UserModal, { userModalOpen } from './UserDialog'

import { MdEngineering, MdFaceRetouchingNatural, MdStar } from 'react-icons/md'
import { useDataProvider } from '../../hooks/useDataProvider'
import { Key } from '@mui/icons-material'
import { useAxios } from '../../hooks/useAxios'

type Props = {}

type ApiKey = {
  token: string;
}

function Users({}: Props) {

  const { users, usersLoading, fetchUsers } = useDataProvider();
  const axios = useAxios();
  const [apiKey, setApiKey] = useState("");

  useEffect(() => {
    if (!users) {
      fetchUsers()
    }
  }, [users, fetchUsers])

  const userIcon = (access:number):JSX.Element => {
    switch (access) {
      case 0:
        return <MdEngineering />

      case 1:
        return <MdFaceRetouchingNatural />
      case 2:
        return <MdStar />
      default:
        return <></>;
    }
  }

  useEffect(() => {
    axios.get<ApiKey>('/users/apikey')
          .then(r => {
            const data = r.data;
            setApiKey(data.token)
          })
  }, [axios, setApiKey])

  return (
    <div>
      <Grid container justifyContent="space-between" spacing={24}>
        <Grid item>
          <Typography component="h2" variant="h6" color="primary" gutterBottom>
            Users
          </Typography>
        </Grid>
        <Grid item>
          <Button 
          onClick={(e) => {
            userModalOpen(emptyUser())
          }}
          variant='contained'>Add User</Button>
        </Grid>
      </Grid>
      <Paper sx={{p:2}}>
        <div style={{ fontFamily: 'monospace' }}>
          API <Key /><br />
          <TextField 
            InputProps={{readOnly: true}}
            fullWidth
            value={apiKey}
            onFocus={event => {
              event.target.select();
            }}
          />
        </div>
      </Paper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>Mobile</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>City</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {usersLoading && (
            <TableRow>
              <TableCell colSpan={5} className="text-center">
                Loading...
              </TableCell>
            </TableRow>
          )}
          {!usersLoading &&
            users?.map((u) => (
              <TableRow key={u.id}>
                <TableCell>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    {userIcon(u.access_level)}
                    <span className="mx-2">{u.email}</span>
                  </div>
                </TableCell>
                <TableCell className="text-center">{u.mobile}</TableCell>
                <TableCell className="text-center">{u.name}</TableCell>
                <TableCell className="text-center">{u.city}</TableCell>
                <TableCell className="text-center">
                  <IconButton
                    aria-label="Edit"
                    onClick={() => {
                      userModalOpen(u);
                    }}
                  >
                    <FaUserEdit />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>

      <UserModal onClose={fetchUsers} />
    </div>
  );
}

export default Users