import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { TextField, Box, Chip } from '@mui/material';

// Define an interface for the props
interface CategoryModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onSave: (name: string) => void;
  initialName?: string;
  title?: string;
}

// Ensure the app element is set
Modal.setAppElement('#root');

// Define the component with typed props
const CategoryModal: React.FC<CategoryModalProps> = ({
  isOpen,
  onRequestClose,
  onSave,
  initialName = '',
  title = 'ADD CATEGORY'
}) => {
  const [name, setName] = useState(initialName);

  useEffect(() => {
    setName(initialName);
  }, [initialName]);

  const handleSaveClick = () => {
    if (name) {
      onSave(name);
      setName('');
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={title}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          width: '35%',
        },
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Chip
          label={<span style={{ fontWeight: 'normal', display: 'block', textAlign: 'left' }}>{title.split('').map((char, index) => (
            <span key={index}>{char.toUpperCase()}&nbsp;</span>
        ))}</span>}
          color="secondary"
          style={{ marginBottom: '10px', width: '100%', borderRadius: 4 }}
        />
      </div>
      <Box sx={{ '& .MuiTextField-root': { m: 1 } }} >
        <div>
          <TextField
            required
            style={{ width: '98%' }}
            value={name}
            onChange={(e) => setName(e.target.value)}
            label="Category Name"
            variant="standard"
          />
        </div>
        <Box mt={2} sx={{ textAlign: 'right' }}>
          <Chip
            label={<span>Save</span>}
            color="primary"
            onClick={handleSaveClick}
            sx={{ width: '80px', borderRadius: 50 }}
          />
          <Chip
            label={<span>Cancel</span>}
            color="warning"
            onClick={onRequestClose}
            sx={{ marginLeft: '10px', width: '80px', borderRadius: 50 }}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default CategoryModal;
