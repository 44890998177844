import { Button, Grid, TextField } from '@mui/material';
import React, { useMemo, useState } from 'react'
import { METER_TYPE_ENUM } from '../../models/Machine';

type Props = {
    callback: (type:number, count: number) => void;
    type: number;
    loading: boolean;
    count: number;
}

function MeterUpdater({ callback, type, loading, count }: Props) {
    
    const [_count, _setCount] = useState(count)
    
    const typeDescription = useMemo(() => {
        return METER_TYPE_ENUM[type]
    }, [type])

    return (
        <Grid item sm={6} md={2}>
            <h1>{typeDescription}</h1>
            <TextField 
                type='number'
                value={_count}
                onChange={(e) => _setCount(parseInt(e.target.value))}
                fullWidth
            />
            <Button variant='contained' disabled={loading} onClick={() => {
                callback(type, _count)
            }}>
                Update
            </Button>
        </Grid>
    );
}

export default MeterUpdater