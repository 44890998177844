import { Machine } from "./Machine";

export interface Customer {
  id?: string;
  external_id: string;
  name: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  contact_name: string;
  contact_number: string;
  contact_email: string;
  note: string;
  created?: string;
  updated?: string;
  hold: boolean;
  division: number;
}

export const emptyCustomer = ():Customer => ({
  id: undefined,
  external_id: '',
  name: '',
  address: '',
  city: '',
  state: '',
  zip: '',
  contact_email: '',
  contact_name: '',
  contact_number: '',
  note: '',
  created: undefined,
  updated: undefined,
  hold: false,
  division: 0
})

export interface CustomerWithMachine extends Customer {
  machines?: Machine[];
}
