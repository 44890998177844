import React, { useCallback, useEffect } from 'react';

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { 
  Divider,
  Table, 
  TableBody, 
  TableCell, 
  TableHead, 
  TableRow,
  Typography
} from '@mui/material';

import { useAxios } from '../../hooks/useAxios';

import { 
  ServiceCall,
  emptyFormattedServiceCall,
  serviceCallToFormattedServiceCall,
  CallItem,
  callItemCategory,
  emptyServiceCall,
  serviceCallTypeDescription,
  getItemName
} from '../../models/ServiceCall';
// import { useDataProvider } from '../../hooks/useDataProvider';
import { Machine, emptyMachine } from '../../models/Machine';
import { Customer, emptyCustomer } from '../../models/Customers';
import { User, emptyUser } from '../../models/User';
import { ApiResponse } from '../../models/ApiResponse';

const BootstrapDialog = styled(Dialog)(({theme}) => ({
  '& .MuiDialogContent-root':{
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root':{
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode
  onClose: () => void;
} 

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{receivedMachine:0, p:2}} {...other}>
      {children}
      {onClose ? (
        <IconButton 
          aria-label="close" 
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ): null}
    </DialogTitle>
  );
};

let printCallModalOpen = (sc_passed:ServiceCall, m_passed:Machine, c_passed:Customer, a_passed:User) => {};
let printCallModalClose = () => {};

type PrintCallModalProps = {
  onClose?: () => void;
}

export default function CustomizedDialogs({ onClose }: PrintCallModalProps) {
  // states
  const [open, setOpen] = React.useState(false);
  const [callToBePrinted, setCalledToBePrinted] = React.useState(emptyFormattedServiceCall());
  const [items, setItems] = React.useState<CallItem[]|undefined>(undefined);
  const [loading, setLoading] = React.useState(false);
  const [callId, setCallId] = React.useState("");
  const [divisionId, setDivisionId] = React.useState<number|string>("");
  
  const axios = useAxios();

  // get data
  const fetchItems = useCallback(() => {
    console.log('fetching items')
    if (callId !== ""){
      setLoading(true)
      axios.get<ApiResponse<CallItem[]>>(`callitem/get?id=${callId}`)
          .then((response) => {
              console.log("response received")
              const responseData = response.data;
              if (responseData.success) {
                console.log("success")
                setItems(responseData.data)
              }
          })
          .finally(() => {
              setLoading(false)
          });
    }
  }, [axios, callId])
  
  useEffect(() => {
    if (callId !== "" && items === undefined)
      fetchItems();
    console.log("rendering...")
  }, [fetchItems, items, callId, open])
  
  // handle openning and closing of modal
  printCallModalOpen = (sc_passed:ServiceCall, m_passed:Machine, c_passed:Customer, a_passed:User) => {
    console.log("printCallModalOpen()")
    setCallId(sc_passed.id as string)
    setDivisionId(sc_passed.division)
    setCalledToBePrinted(serviceCallToFormattedServiceCall(sc_passed, m_passed, c_passed, a_passed))
    setOpen(true)
  }

  printCallModalClose = () => {
    console.log("printCallModalClose()")
    setItems(undefined)
    setCalledToBePrinted(emptyFormattedServiceCall())
    setCallId("")
    setOpen(false)
    if(onClose) {
      onClose()
    }
  }

  // const numberFormat = (value:number) =>
  //   new Intl.NumberFormat('en-US', {
  //       style: 'currency',
  //       currency: 'USD'
  //   }).format(value);

  const numberFormat = (value:number) =>
  new Intl.NumberFormat(divisionId === 5 ? 'en-PH' : 'en-US', {
      style: 'currency',
      currency: divisionId === 5 ? 'PHP' : 'USD'
  }).format(value);    

  // Calculate subtotal
  const gTotal = items && items.reduce((acc, i) => acc + i.amount, 0);
    
  return (
    <div>
      {loading && <>Loading...</>}

      {!loading && 
        <BootstrapDialog
          onClose = {(_, reason) => {
            if (reason !== "backdropClick") {
              return false;
            }
          }}
          sx={{
            "& .MuiDialog-Container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "800px",
              },
            },
          }}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <BootstrapDialogTitle 
            id="customized-dialog-title" 
            onClose={printCallModalClose}
          >
            <u><b>WOID:</b></u> { callToBePrinted.call.external_id ?? "" }
          </BootstrapDialogTitle>
          <DialogContent>
            <Typography variant="body1"><b><u>Assignee:</u></b> { callToBePrinted.assigned_to?.name ?? "---" }</Typography>
            <Typography variant="body1"><b><u>Customer:</u></b> { callToBePrinted.customer?.name ?? "---"}</Typography>
            <Typography variant="body1"><b><u>Call Type:</u></b> { serviceCallTypeDescription(callToBePrinted.call.call_type ?? -1) }</Typography>
            <Typography variant="body1"><b><u>Machine:</u></b> { callToBePrinted.machine?.model ?? "" } { callToBePrinted.machine?.serial ?? "" }</Typography>
            <TextField 
              id="outlined-basic" 
              label="Description" 
              defaultValue={callToBePrinted.description ?? ""} 
              disabled
              fullWidth
              sx={{mb: 2, mt:2}}
            />
            <Typography variant="body1" mb={2}><b><u>Machine Location:</u></b> { callToBePrinted.machine?.address ?? ""} { callToBePrinted.machine?.city ?? ""} { callToBePrinted.machine?.state ?? ""} { callToBePrinted.machine?.zip ?? ""}</Typography>
            {/* <Typography variant="body1"><b><u>Items:</u></b></Typography> */}
            <Divider />
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ backgroundColor: 'lightblue' }} colSpan={4}><b>S E R V I C E S :</b></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Item</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>#</TableCell>
                  <TableCell style={{textAlign: 'center'}}>Cost</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items &&
                  items.map((i) => (
                    <TableRow key={i.id}>
                      <TableCell>{getItemName(divisionId,i.category_id,i.item_id)}</TableCell>
                      <TableCell>{i.description}</TableCell>
                      <TableCell>{i.quantity}</TableCell>
                      <TableCell style={{textAlign: 'right'}}>{numberFormat(i.amount)}</TableCell>
                    </TableRow>
                ))}
                <TableRow>
                  <TableCell style={{textAlign: 'center'}} colSpan={3}><b>T O T A L</b></TableCell>
                    <TableCell style={{textAlign: 'right'}}><b>{numberFormat(gTotal as number)}</b></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions>
            <Button
              color="success"
              onClick={() => { window.print() }}
            >
              Print Call
            </Button>
          </DialogActions>
        </BootstrapDialog>
      }
    </div>
  )
}

export { printCallModalOpen, printCallModalClose }
