import { createContext, useState } from 'react';

type AuthInfo = {
    guid: string;
    email: string;
    token?: string;
    expiry?: Date;
    access?: 'Admin' | 'Technician' | 'SuperAdmin' | undefined
};


const initialAuthInfo: AuthInfo = {
    guid: '',
    email: '',
    token: undefined,
    expiry: undefined,
    access: undefined
};

type authContext = {
    setAuth?: React.Dispatch<React.SetStateAction<AuthInfo>>;
    auth?: AuthInfo;
};

export const AuthContext = createContext<authContext>({});

export const saveAuth = (info: AuthInfo) => {
    const js = JSON.stringify(info);
    const bs = btoa(js);


    localStorage.setItem('fsstok', bs);
}

export const clearAuth = () => {
    localStorage.removeItem('fsstok')
}

let restoreAuth = ():AuthInfo | undefined => {
    return;
}

type Props = {
    children: React.ReactNode;
};

function AuthProvider({ children }: Props) {
    const [auth, setAuth] = useState(initialAuthInfo);

    restoreAuth = ():AuthInfo | undefined => {   
        console.info(`restoreAuth() invoked`)
        const bs = localStorage.getItem('fsstok');
        if (!bs) {
            return;
        }

        //TODO: Check expiry

        const js = atob(bs);
        const _auth = JSON.parse(js);
        setAuth(_auth);

        return (_auth as AuthInfo);
    }
    return <AuthContext.Provider value={{ auth, setAuth }}>{children}</AuthContext.Provider>;
}

export default AuthProvider;
export { restoreAuth }
