import { Button, Grid } from '@mui/material'
import React, { useState } from 'react'
import { emptyMachine, Machine } from '../../models/Machine'
import MachineView from './MachineView'

type Props = {
    machines?: Machine[],
    customerId?: string
}

function Machines({ machines, customerId }: Props) {
  
    const [_machines, setMachines] = useState(machines ?? []);

    const addMachine = () => {
      setMachines([emptyMachine(customerId as string), ..._machines])
    }

    const cancelAdd = (id?:string) => {
      setMachines(_machines.filter(m => m.id !== id))
    }

    return (
      <div style={{ marginBottom: "1em" }}>
        <Grid container spacing={1}>
          <Grid item sm={10}>
            {_machines.map((machine, i) => (
              <MachineView machine={machine} key={i} customerId={customerId} cancelAdd={cancelAdd} />
            ))}
          </Grid>
          <Grid item sm={2}>
            <Button variant='contained' fullWidth onClick={addMachine}>
              Add
            </Button>
          </Grid>
        </Grid>
      </div>
    );
}

export default Machines