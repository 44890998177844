import React, { useState } from 'react';
import { Grid, Box } from '@mui/material';
import BlankProfile from './../assets/blankprofile.jpg';

interface UserImageProfileProps {
  imageSrc: string;
  userProfId: string | undefined;
  onClick?: (e: React.MouseEvent<HTMLImageElement>) => void;
}

const UserImageProfile: React.FC<UserImageProfileProps> = ({ imageSrc, userProfId, onClick }) => {
  const [imgSrc, setImgSrc] = useState<string>(imageSrc);
  const [userProfileId, setUserProfileId] = useState<string | undefined>(userProfId);

  const handleImageError = () => {
    setImgSrc(BlankProfile);
  };

  const handleClick = (e: React.MouseEvent<HTMLImageElement>) => {
    e.stopPropagation();
    console.log(userProfId);
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <Grid container justifyContent="center" alignItems="center" sx={{ minWidth: 'fit-content' }}>
      <Grid item>
        <Box
          component="img"
          src={imgSrc}
          sx={{
            width: '30px',
            height: '30px',
            borderRadius: '50%',
            border: '2px solid white',
            cursor: 'pointer',
            transition: 'transform 0.2s ease-in-out',
            '&:hover': {
              transform: 'scale(1.3)',
            },
          }}
          onError={handleImageError}
          alt={`User ${userProfId}`}
          onClick={handleClick} 
          style={{ cursor: 'default' }}
        />
      </Grid>
    </Grid>
  );
};

export default UserImageProfile;
