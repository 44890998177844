export interface User {
  name: string;
  email: string;
  id: string | undefined;
  access_level: number;
  active: boolean;
  mobile?: string;
  city?: string;
  password?: string;
  external_id?: string;
}

export const emptyUser = (): User => ({
  name: '',
  email: '',
  id: undefined,
  access_level: 0,
  active: true,
  mobile: '',
  city: '',
  password: ''
})