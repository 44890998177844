import React from 'react';
import { Dialog, DialogContent, DialogTitle, Box, Typography, Divider, DialogActions, IconButton, Chip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { User } from '../../models/User';

interface UserProfileProps {
  open: boolean;
  onClose: () => void;
  imageProfile: string | undefined;
  tech: User | undefined;
}

const UserProfile: React.FC<UserProfileProps> = ({ open, onClose, imageProfile, tech }) => {
  const theme = useTheme();
  return (

    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          transform: open ? 'scale(1)' : 'scale(0.5)',
          transition: 'transform 0.3s ease-in-out',
          boxShadow: 24,
          width: '300px',
          // height: '350px',
          maxWidth: '300px',
          maxHeight: '450px',
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: 'center',
          textTransform: 'uppercase',
          backgroundColor: 'primary.main',
          color: 'white',
          padding: 2,
        }}
      >{tech?.name ? tech?.name : 'Tech Details'}</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <Box
            component="img"
            src={imageProfile}
            sx={{
              width: 120,
              height: 120,
              borderRadius: '50%',
              mt: 3,
              mb: 2,
              border: '2px solid white',
              boxShadow: 2,
              cursor: 'pointer',
              transition: 'transform 0.2s ease-in-out',
              '&:hover': {
                transform: 'scale(1.2)',
              },
            }}
            alt={`User Id ${tech?.name}`}
          />
          <hr style={{ width: '100%', margin: '10px 0' }} />
          <Typography variant="body1">{tech?.mobile}</Typography>
          <Typography variant="body1">{tech?.email}</Typography>
          <Typography variant="body1">{tech?.city}</Typography>
          {tech?.active && (<Typography>
            <Chip
            sx={{ ml: 1 }}
            size="small"
            color="success"
            variant="outlined"
            label={tech?.active ? 'Active' : ''}
          />
          </Typography>)}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="center" width="100%">
          <IconButton onClick={onClose} color="primary" aria-label="close dialog">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogActions>
    </Dialog>

  );
};

export default UserProfile;
