export interface Machine {
    id?: string;
    customer_id: string;
    external_id: string;
    brand: string;
    make: string;
    model: string;
    serial: string;
    meter_counts?: MeterRead[];
    note?: string;
    created?: string;
    updated?: string;
    contract: boolean;
    contract_level: string;
    expiry_date: string;
    address: string;
    city: string;
    state: string;
    zip: string;
    onsite_location: string;
    contact_name: string;
    contact_number: string;
    contact_email: string;
  }

export type MeterRead = {
    updated?: string;
    type: number;
    count: number;
}

export const METER_TYPE_ENUM = [
  "BlackWhite",
  "Color",
  "Tier1",
  "Tier2",
  "Tier3",
]

export const emptyMachine = (customerId: string): Machine => {
  return {
    id: undefined,
    brand: '',
    customer_id: customerId,
    external_id: '',
    make: '',
    model: '',
    serial: '',
    contract: false,
    contract_level: '',
    expiry_date: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    onsite_location: '',
    contact_name: '',
    contact_number: '',
    contact_email: ''
  }
}